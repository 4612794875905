import { memo } from 'react';

const CampaignURLReportTableHeader = () => {
  return (
    <div className="url-table-row">
      <div className="text-left">#</div>
      <div className="text-left">Type</div>
      <div className="text-center">Timeframe</div>
      <div className="text-left">URL Report</div>
      <div>Links Built</div>
      <div> + / Δ </div>
      <div>SoV</div>
      <div> + / Δ </div>
      <div>Page Authority</div>
      <div> + / Δ </div>
      <div>Avg. Rank</div>
      <div> + / Δ </div>
      <div>Ranking KWs</div>
      <div> + / Δ </div>
    </div>
  );
};
export default memo(CampaignURLReportTableHeader);
