import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Label, Row, Col, Form, Input } from 'reactstrap';
import Button from '@components/ButtonLoader';
import { useAddMutation } from '@components/linkcountdocuments/linkcountdocumentsApi';
import { showError, showSuccess } from '@components/Notifications';
import T from '@json/en.json';

const formInitialState = {
  name: '',
  sheet_id: '',
};
const requiredFields = ['name', 'sheet_id'];

const AddLinkcountDocument = () => {
  const history = useHistory();
  const [addDocument, addDocumentResult] = useAddMutation();
  const [formData, setFormData] = useState(formInitialState);
  const [formDataError, setFormDataError] = useState({});

  const handleFormChange = ({ target }) => {
    setFormData(i => ({ ...i, [target.name]: target.value }));
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    setFormDataError({});

    for (const field of requiredFields) {
      if (!formData[field]) {
        setFormDataError(i => ({ ...i, [field]: true }));
      }
    }

    if (!requiredFields.every(i => formData[i])) {
      return false;
    }

    const { error } = await addDocument(formData);

    if (error) {
      if (error.status === 400 && error.data.errors.sheet_id) {
        return showError('Sheet ID is not valid!');
      }
      return showError('Error adding Document!');
    }

    showSuccess('Document added');

    return history.push('/linkcountdocuments');
  };

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      {/* Page Header Start */}
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <h3 className="page-title">
            {T.LINKCOUNTDOCUMENT.ADDLINKCOUNTDOCUMENT}
            <Link to="/linkcountdocuments" className="btn btn-sm btn-danger back-button pull-right">
              {` << ${T.GENRIC.BACK}`}
            </Link>
          </h3>
        </Col>
      </Row>
      {/* Page Header End */}

      {/* Page Body (Form) Start */}
      <div className="card card-default">
        <div className="card-body">
          <Form id="add-linkcountdocument-form" onSubmit={e => onFormSubmit(e)}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Label title="Required" className="fs mb-1">
                    {T.LINKCOUNTDOCUMENT.FIELD_NAMES.NAME}
                    {!formData.name && <span className="text-danger ms-1">*</span>}
                  </Label>
                  <Input
                    name="name"
                    disabled={false}
                    type="text"
                    placeholder={T.LINKCOUNTDOCUMENT.FIELD_NAMES.NAME}
                    className="form-control"
                    onChange={handleFormChange}
                    value={formData.name}
                  />
                  <div className="form-error mb-3 mt-0">{formDataError.name ? T.ERRORS.EMPTY : ''}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Label title="Required" className="fs mb-1">
                    {T.LINKCOUNTDOCUMENT.FIELD_NAMES.SHEET_ID}
                    {!formData.sheet_id && <span className="text-danger ms-1">*</span>}
                  </Label>
                  <Input
                    name="sheet_id"
                    disabled={false}
                    type="text"
                    placeholder={T.LINKCOUNTDOCUMENT.FIELD_NAMES.SHEET_ID}
                    className="form-control"
                    onChange={handleFormChange}
                    value={formData.sheet_id}
                  />
                  <div className="form-error mb-3 mt-0">{formDataError.sheet_id ? T.ERRORS.EMPTY : ''}</div>
                </div>
              </div>
            </div>
            <Button disabled={!requiredFields.every(i => formData[i])} loading={addDocumentResult.isLoading} className="btn btn-accent" type="submit">
              {T.GENRIC.SUBMIT}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default AddLinkcountDocument;
