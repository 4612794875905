// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.url-report-control {
  display: flex;
  justify-content: space-between;
}
.url-report-control .btn-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}
.url-report-control .btn-container button {
  height: 30px;
}

.add-url-description {
  font-weight: 100;
  font-size: 0.8rem;
  position: relative;
  top: -8px;
}`, "",{"version":3,"sources":["webpack://./src/components/campaigns/campaign.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AAAE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAEJ;AADI;EACE,YAAA;AAGN;;AACA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;AAEF","sourcesContent":[".url-report-control {\n  display: flex;\n  justify-content: space-between;\n  .btn-container {\n    display: flex;\n    align-items: flex-end;\n    padding-bottom: 5px;\n    button {\n      height: 30px;\n    }\n  }\n}\n.add-url-description {\n  font-weight: 100;\n  font-size: 0.8rem;\n  position: relative;\n  top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
