import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';
import BugsnagPerformance from '@bugsnag/browser-performance';

const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  tagTypes: ['Reports'],
  endpoints: builder => ({
    get: builder.query({
      query: ({ campaign }) => (campaign && campaign.id ? `campaigns/${campaign.id}/reports` : 'reports/'),
      providesTags: response => {
        if (!response) {
          return [{ type: 'Reports', id: 'LIST' }];
        }
        return [...response.results.map(i => ({ type: 'Reports', id: i.id })), { type: 'Reports', id: 'LIST' }];
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('reportsApi.get');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    delete: builder.mutation({
      query: report => ({ url: `reports/${report.id}`, method: 'DELETE' }),
      invalidatesTags: (results, error, { id }) => {
        return [{ type: 'Reports', id }];
      },
    }),
  }),
});

export const { useGetQuery, useDeleteMutation } = reportsApi;

export default reportsApi;
