import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import dashboardApi from '@components/dashboard/dashboardApi';
import campaignsApi from '@components/campaigns/campaignsApi';
import linkcountdocumentsApi from '@components/linkcountdocuments/linkcountdocumentsApi';
import reportsApi from '@components/reports/reportsApi';
import loginApi from '@components/login/loginApi';
import appSlice from '@redux/appSlice';
import urlsApi from '@components/urls/urlsApi';
import { isRejected } from '@reduxjs/toolkit';
import { showError } from '@components/Notifications';
import { signOut } from '@utils';

const rtkQueryErrorLogger = () => next => action => {
  if (isRejected(action) && action.payload && action.payload.data) {
    const message = action.payload.data.message;
    console.warn(`Rejected query with message: ${message || '?'}`);
    if (message) {
      showError(message);
    }
  }

  return next(action);
};

const authMiddleware = () => next => action => {
  if (isRejected(action) && action.payload && action.payload.status === 401 && action.meta.arg.endpointName !== 'login') {
    signOut();
    window.location = '/';
    showError(`Session expired - Log in again`);
  }

  return next(action);
};

const middlewares = [
  authMiddleware,
  rtkQueryErrorLogger,
  dashboardApi.middleware,
  campaignsApi.middleware,
  reportsApi.middleware,
  loginApi.middleware,
  urlsApi.middleware,
  linkcountdocumentsApi.middleware,
];

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [linkcountdocumentsApi.reducerPath]: linkcountdocumentsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [urlsApi.reducerPath]: urlsApi.reducer,
  },

  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
});

setupListeners(store.dispatch);
