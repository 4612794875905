// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* add support for dark theme */
.QEditor_qEditor__VwYxr {
  margin: 10px 0 20px;
  position: relative;
}

.QEditor_editorWrapper__1Jms7 {
  position: relative;
  border: 1px solid #007bff;
  border-radius: 4px;
}
.QEditor_editorWrapper__1Jms7 .QEditor_qlContainer__Qjlzw {
  min-height: 300px;
}

.QEditor_actionWrapper__3npYt {
  display: flex;
  position: absolute;
  width: 100px;
  top: 2px;
  right: 10px;
  justify-content: space-evenly;
}
.QEditor_actionWrapper__3npYt i {
  font-size: 24px;
}
.QEditor_actionWrapper__3npYt .QEditor_save__rqPT2 {
  z-index: 99;
  background-color: #007bff;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  font-weight: bold;
  align-self: center;
  height: 32px;
  display: flex;
  width: 160px;
}

.QEditor_textContent__b5Rkw {
  min-height: 20px;
  position: relative;
  padding: 8px 15px;
  cursor: pointer;
}

.QEditor_textContent__b5Rkw img {
  max-width: 100%;
}

.QEditor_placeholderText__0p97D {
  color: #bbb;
  cursor: pointer;
  padding: 8px 15px;
}

.QEditor_editIcon__gLmti {
  color: #aaa;
  position: absolute !important;
  right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/shared/_color.scss","webpack://./src/elements/QEditor/QEditor.module.scss"],"names":[],"mappings":"AAAA,+BAAA;ACCA;EACE,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;AAEF;AADE;EACE,iBAAA;AAGJ;;AACA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,QAAA;EACA,WAAA;EACA,6BAAA;AAEF;AADE;EACE,eAAA;AAGJ;AADE;EACE,WDtBoB;ECuBpB,yBDzBG;EC0BH,WDzBI;EC0BJ,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAGJ;;AAAA;EACE,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,WD1CO;EC2CP,eAAA;EACA,iBAAA;AAKF;;AAHA;EACE,WAAA;EACA,6BAAA;EACA,WAAA;AAMF","sourcesContent":["/* add support for dark theme */\n$blue: #007bff;\n$white: #fff;\n$editableTextboxZIndex: 99;\n$gray: #eee;\n$gray-a: #aaa;\n$gray-b: #bbb;\n$gray-9: #999;\n","@import '@shared/color';\n.qEditor {\n  margin: 10px 0 20px;\n  position: relative;\n}\n.editorWrapper {\n  position: relative;\n  border: 1px solid $blue;\n  border-radius: 4px;\n  .qlContainer {\n    min-height: 300px;\n  }\n}\n\n.actionWrapper {\n  display: flex;\n  position: absolute;\n  width: 100px;\n  top: 2px;\n  right: 10px;\n  justify-content: space-evenly;\n  i {\n    font-size: 24px;\n  }\n  .save {\n    z-index: $editableTextboxZIndex;\n    background-color: $blue;\n    color: $white;\n    border: none;\n    box-shadow: none;\n    border-radius: 4px;\n    font-weight: bold;\n    align-self: center;\n    height: 32px;\n    display: flex;\n    width: 160px;\n  }\n}\n.textContent {\n  min-height: 20px;\n  position: relative;\n  padding: 8px 15px;\n  cursor: pointer;\n}\n.textContent img {\n  max-width: 100%;\n}\n.placeholderText {\n  color: $gray-b;\n  cursor: pointer;\n  padding: 8px 15px;\n}\n.editIcon {\n  color: #aaa;\n  position: absolute !important;\n  right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"qEditor": `QEditor_qEditor__VwYxr`,
	"editorWrapper": `QEditor_editorWrapper__1Jms7`,
	"qlContainer": `QEditor_qlContainer__Qjlzw`,
	"actionWrapper": `QEditor_actionWrapper__3npYt`,
	"save": `QEditor_save__rqPT2`,
	"textContent": `QEditor_textContent__b5Rkw`,
	"placeholderText": `QEditor_placeholderText__0p97D`,
	"editIcon": `QEditor_editIcon__gLmti`
};
export default ___CSS_LOADER_EXPORT___;
