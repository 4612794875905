import { useMemo } from 'react';
import { Progress } from 'reactstrap';
import clsx from 'clsx';

const CampaignStatus = ({ busyProgress, status }) => {
  const inProgress = status === 1;
  const isFailure = status === 3;
  const isReady = status === 2;

  const { buttonColor, buttonText } = useMemo(() => {
    if (isFailure) {
      return { buttonColor: 'danger', buttonText: 'Failure' };
    }

    if (inProgress && busyProgress === 0) {
      return { buttonColor: 'warning', buttonText: 'Queued' };
    }

    if (inProgress) {
      return { buttonColor: 'warning', buttonText: 'Loading...' };
    }

    if (isReady) {
      return { buttonColor: 'success', buttonText: 'Ready' };
    }

    return { buttonColor: 'secondary', buttonText: 'New' };
  }, [isFailure, inProgress, busyProgress, isReady]);

  return (
    <Progress
      value={inProgress ? busyProgress * 100 : 100}
      animated={false}
      color={buttonColor}
      barStyle={{ height: 24, width: 120, overflow: 'visible' }}
      style={{ height: 24, width: 120, margin: '0 auto' }}
    >
      <div className={clsx('absolute', inProgress ? 'text-black' : 'text-white')} style={{ width: 120, fontSize: '0.75rem' }}>
        {buttonText}
      </div>
    </Progress>
  );
};

export default CampaignStatus;
