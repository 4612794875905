import { useState, useCallback } from 'react';
import { Input } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ucFirst } from '@utils';
import { MdLogout } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import tempAvat from '../assets/images/avatars/0.jpg';
import T from '@json/en.json';

const TopBar = () => {
  const history = useHistory();
  const user = useSelector(state => state.app.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.value = '';
    }
  }, []);

  return (
    <div className="main-navbar bg-white">
      <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0 mx-px">
        <form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless ml-3">
            <div className="input-group-prepend">
              <div className="input-group-text global-search">
                <i className="fa fa-search mt-2"></i>
              </div>
            </div>
            <Input onKeyDown={handleKeyDown} type="text" className="navbar-search form-control" placeholder={T.TOPBAR.SEARCHINPUT_PLACEHOLDER} />
          </div>
        </form>
        <div className="border-left">
          <Dropdown className="h-100" isOpen={dropdownOpen} toggle={() => null}>
            <DropdownToggle
              caret
              data-toggle="dropdown"
              tag="div"
              className="h-100 px-2 d-flex justify-content-between align-items-center cursor-pointer"
              onClick={() => setDropdownOpen(i => !i)}
            >
              <div className="px-2">
                <img className="user-avatar rounded-circle" src={tempAvat} alt="User Avatar" />
              </div>
              <p className="m-1">{ucFirst(user.username)}</p>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => history.push('/logout')} className="logout-button-color">
                <MdLogout /> Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </nav>
    </div>
  );
};

export default TopBar;
