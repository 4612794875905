import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';
import BugsnagPerformance from '@bugsnag/browser-performance';

const linkcountdocumentsApi = createApi({
  reducerPath: 'linkcountdocumentsApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  tagTypes: ['Linkcountdocuments'],
  endpoints: builder => ({
    add: builder.mutation({
      query: lcd => ({ url: `linkcountdocuments/`, method: 'POST', body: lcd }),
      invalidatesTags: (results, error, { id }) => {
        return [{ type: 'Linkcountdocuments', id }];
      },
    }),
    get: builder.query({
      query: () => 'linkcountdocuments/',
      providesTags: response => {
        if (!response) {
          return [{ type: 'Linkcountdocuments', id: 'LIST' }];
        }
        return [...response.results.map(i => ({ type: 'Linkcountdocuments', id: i.id })), { type: 'Linkcountdocuments', id: 'LIST' }];
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('linkcountdocumentsApi.get');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    fetch: builder.mutation({
      query: ids => ({ url: `linkcountdocuments/fetch`, method: 'POST', body: { ids } }),
    }),
    update: builder.mutation({
      query: lcd => ({ url: `linkcountdocuments/${lcd.id}`, method: 'PATCH', body: lcd }),
      invalidatesTags: (results, error, { id }) => {
        return [{ type: 'Linkcountdocuments', id }];
      },
    }),
    delete: builder.mutation({
      query: lcd => ({ url: `linkcountdocuments/${lcd.id}`, method: 'DELETE' }),
      invalidatesTags: (results, error, { id }) => {
        return [{ type: 'Linkcountdocuments', id }];
      },
    }),
  }),
});

export const { useAddMutation, useGetQuery, useUpdateMutation, useDeleteMutation, useFetchMutation } = linkcountdocumentsApi;

export default linkcountdocumentsApi;
