import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from '@redux/store';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { injectStyle as injectToastifyStyle } from 'react-toastify/dist/inject-style';
import 'react-datepicker/dist/react-datepicker.css';

const APP_VERSION = process.env.REACT_APP_VERSION || '?';
const BUGSNAG_KEY = process.env.REACT_APP_BUGSNAG_KEY || 'Please set a value for REACT_APP_BUGSNAG_KEY at .env file!';
const bugsnagEnabledReleaseStages = (process.env.REACT_APP_BUGSNAG_ENABLE_RELEASE_STAGES || 'production,development').split(',').map(i => i.trim());

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: bugsnagEnabledReleaseStages,
  appVersion: APP_VERSION,
});

BugsnagPerformance.start({
  apiKey: BUGSNAG_KEY,
  releaseStage: process.env.NODE_ENV || '?',
  enabledReleaseStages: bugsnagEnabledReleaseStages,
  appVersion: APP_VERSION,
  networkRequestCallback: requestInfo => {
    if (['script', 'iframe'].includes(requestInfo.type)) {
      return null;
    }

    if (['127.0.0.1', 'localhost'].some(i => requestInfo.url && requestInfo.url.includes(i))) {
      return null;
    }

    return requestInfo;
  },
  settleIgnoreUrls: [],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

injectToastifyStyle();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
