// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.dim {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-80 {
  opacity: 0.8;
}

.highlighted-row {
  transition: background-color 0.5s ease;
  background-color: rgb(240, 240, 240);
}

.pre-line {
  white-space: pre-line;
}

.full-width {
  width: 100%;
}

.inline-radio,
.row > .inline-radio {
  margin: 10px 50px 10px 0;
  padding: 0.25rem 0.5rem;
  width: auto;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.highcharts-credits {
  opacity: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,8JAAA;EACA,mCAAA;EACA,kCAAA;AACF;;AAEA;EACE,+EAAA;AACF;;AAEA;EACE,YAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,sCAAA;EACA,oCAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AAAA;;EAEE,wBAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;AAGF;;AAAA;EACE,eAAA;AAGF;;AAAA;EACE,qBAAA;AAGF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n.dim {\n  opacity: 0.3;\n  pointer-events: none;\n  cursor: not-allowed;\n}\n\n.opacity-10 {\n  opacity: 0.1;\n}\n\n.opacity-30 {\n  opacity: 0.3;\n}\n\n.opacity-40 {\n  opacity: 0.4;\n}\n\n.opacity-80 {\n  opacity: 0.8;\n}\n\n.highlighted-row {\n  transition: background-color 0.5s ease;\n  background-color: rgb(240, 240, 240);\n}\n.pre-line {\n  white-space: pre-line;\n}\n\n.full-width {\n  width: 100%;\n}\n.inline-radio,\n.row > .inline-radio {\n  margin: 10px 50px 10px 0;\n  padding: 0.25rem 0.5rem;\n  width: auto;\n  cursor: pointer;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.highcharts-credits {\n  opacity: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
