import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Table, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Translation
import T from '@json/en.json';

const AllSummary = () => {
  const [summaryList, setSummaryList] = useState([]);

  const nextProps = useSelector(state => ({
    summaryListData: state.Campaigns.summaryListData || null, //It will change
    isSummaryListRequesting: state.Campaigns.isSummaryListRequesting, //It will change
    summaryListDataError: state.Campaigns.summaryListDataError, //It will change
  }));

  useEffect(() => {
    if (nextProps.summaryListData) {
      const {
        data: { result },
      } = nextProps.summaryListData;
      if (result?.results?.length) {
        setSummaryList(result?.results);
      }
    }
  }, [nextProps.summaryListData]);

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <span className="text-uppercase page-subtitle">{T.OVERVIEW.TITILE}</span>
          <h3 className="page-title">
            {T.SUMMARY.ALLSUMMARY}
            <Link to="/campaigns" className="btn btn-sm btn-danger back-button pull-right">
              {` << ${T.GENRIC.BACK}`}
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{T.SUMMARY.ALLSUMMARY}</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              {nextProps.isSummaryListRequesting && (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
              <Table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{T.SUMMARY.FIELD_NAMES.TITILE}</th>
                    <th scope="col">{T.SUMMARY.FIELD_NAMES.CAMPAIGN_NAME}</th>
                    <th scope="col">{T.SUMMARY.FIELD_NAMES.STATUS}</th>
                    <th scope="col">{T.SUMMARY.FIELD_NAMES.ACTIONS}</th>
                  </tr>
                </thead>

                <tbody>
                  {summaryList.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.name}</td>
                        <td>Date</td>
                        <td>
                          <Button type="button" disabled className="mb-2 btn btn-sm btn-success mr-1">
                            Ready
                          </Button>
                        </td>
                        <td>-</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {summaryList?.length === 0 && (
                <div>
                  <div className="no-campaign-data">{T.GENRIC.NO_RECORDS_FOUND}</div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AllSummary;
