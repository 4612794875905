import { toast } from 'react-toastify';

export const showError = message => {
  return toast.error(<span className="error-message">{message}</span>, {
    hideProgressBar: false,
    autoClose: 5000,
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const showSuccess = (message, opts = {}) => {
  return toast.success(<span className="success-message">{message}</span>, {
    hideProgressBar: opts.hideProgressBar || false,
    autoClose: opts.autoClose || 5000,
    position: toast.POSITION.TOP_RIGHT,
  });
};
