export const colors = {
  blue: '#91989e',
  black: '#000',
  darkGray: '#999',
  darkOrange: '#ed7d31',
  darkRed: '#B32824',
  green: '#e56874',
  gray: '#a5a5a5',
  lightGray: '#b2b4b8',
  orange: '#ffd145',
  red: '#5ebd74',
  tealBlue: '#4472c4',
  teal: '#5b9bd5',
  yellow: '#ffc000',
};

export const createYAxis = ({
  seriesName,
  showAxisTicks = false,
  showAxisBorder = false,
  showLabel = false,
  color,
  title,
  opposite = false,
  reversed = false,
  showIntegersOnY = false,
  axisBorderOffsetX = 0,
  ...rest
}) => {
  return {
    seriesName,
    opposite,
    reversed,
    axisTicks: {
      show: showAxisTicks,
    },
    axisBorder: {
      show: showAxisBorder,
      color,
      offsetX: axisBorderOffsetX,
    },
    labels: {
      show: showLabel,
      style: {
        colors: [color],
      },
      ...(showIntegersOnY
        ? {
            formatter: val => {
              return parseInt(val);
            },
          }
        : {}),
    },
    markers: {
      size: [4, 4, 4, 4, 4, 4],
    },
    ...(title ? { title: { text: title, style: { color, fontSize: '12px', fontWeight: 300 } } } : {}),
    ...rest,
  };
};

class GraphRange {
  client = { min: 0, max: 0 };
  control = { min: 0, max: 0 };

  constructor({ clientMin, clientMax, controlMin, controlMax }) {
    this.client = { min: clientMin, max: clientMax };
    this.control = { min: controlMin, max: controlMax };
  }
}

const padValues = (clientMin, controlMin, clientMax, controlMax, zoom = 1) => {
  const mins = [clientMin, controlMin];
  const maxs = [clientMax, controlMax];
  const step = 0.2 * zoom;

  const [clientBottom, controlBottom] = mins
    .map(value => {
      if (value <= 1) {
        return 0;
      }
      if (value <= 2) {
        return value - step;
      }
      if (value <= 10) {
        return value - 2 * step;
      }
      if (value <= 20) {
        return value - 4 * step;
      }
      return value - 10 * step;
    })
    .map(i => (i < 0 ? 0 : i));

  const [clientTop, controlTop] = maxs.map(value => {
    if (value <= 2) {
      return value + step;
    }
    if (value <= 10) {
      return value + 2 * step;
    }
    if (value <= 20) {
      return value + 4 * step;
    }
    return value + 10 * step;
  });

  return new GraphRange({
    clientMin: clientBottom,
    controlMin: controlBottom,
    clientMax: clientTop,
    controlMax: controlTop,
  });
};

export const getClientControlYRange = ({ clientArr, controlArr, zoom }) => {
  const clientMax = Math.max(...clientArr);
  const clientMin = Math.min(...clientArr);
  const controlMax = Math.max(...controlArr);
  const controlMin = Math.min(...controlArr);

  return padValues(clientMin, controlMin, clientMax, controlMax, zoom);
};
