import { useCallback, useState, useEffect, Fragment } from 'react';
import { Label } from 'reactstrap';
import T from '@json/en.json';
import QEditor from '@elements/QEditor/QEditor';
import { extractTextContent } from '@utils';

const METRICS = [
  {
    label: 'General Comments',
    key: 'general',
  },
  {
    label: 'Share of Voice',
    key: 'share_of_voice',
  },
  {
    label: 'Page Authority',
    key: 'page_authority',
  },
  {
    label: 'Link Building',
    key: 'link_building',
  },
  {
    label: 'Keyword Rankings',
    key: 'keyword_ranking',
  },
];

const SummaryAndFindings = ({ initialContent = {}, onChange = () => true, onSave = () => true, errors = {}, isSaving = false, onUpdateEditable = () => true }) => {
  const editorProps = {
    textPlaceholder: 'Enter Description',
    textContentStyle: {
      border: '1px solid #e1e5eb',
      borderRadius: '4px',
    },
    placeholderStyle: {
      fontSize: '13px',
      color: '#aaa',
      fontWeight: '100',
      border: '1px solid #e1e5eb',
      borderRadius: '4px',
    },
    limitCharacters: false,
    isSaving,
  };

  const [editingKeys, setEditingKeys] = useState([]);

  useEffect(() => {
    onUpdateEditable(editingKeys);
  }, [editingKeys, onUpdateEditable]);

  const handleUpdateEditable = (value, key) => {
    if (value && !editingKeys.includes(key)) {
      return setEditingKeys(i => [...i, key]);
    }

    setEditingKeys(i => i.filter(i => i !== key));
  };

  const handleChange = useCallback(
    (content, key) => {
      if (!extractTextContent(content)) {
        return onChange('', key);
      }
      onChange(content, key);
    },
    [onChange]
  );

  const getContentUpdateHandlerFor = useCallback(
    key => async content => {
      if (isSaving) {
        return false;
      }
      if (!extractTextContent(content)) {
        onChange('', key);
        return await onSave('', key);
      }

      onChange(content, key);
      await onSave(content, key);
    },
    [onChange, onSave, isSaving]
  );

  const renderMetrics = metric => {
    return (
      <Fragment key={metric.key}>
        <Label className="fs mb-1">{metric.label}</Label>
        <QEditor
          {...editorProps}
          onUpdateEditable={value => handleUpdateEditable(value, metric.key)}
          initialContent={initialContent[metric.key] || ''}
          onChange={i => handleChange(i, metric.key)}
          updateContent={getContentUpdateHandlerFor(metric.key)}
        />
        <div className="form-error mb-3 mt-0">{errors[metric.key] ? T.ERRORS.EMPTY : ''}</div>
      </Fragment>
    );
  };

  return <div>{METRICS.map(renderMetrics)}</div>;
};

export default SummaryAndFindings;
