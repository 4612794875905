import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';
import BugsnagPerformance from '@bugsnag/browser-performance';

const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  endpoints: builder => ({
    get: builder.query({
      query: () => 'dashboard/',
    }),
    async onQueryStarted(_, { queryFulfilled }) {
      const bugsnagSpan = BugsnagPerformance.startSpan('dashboardApi.get');
      await queryFulfilled;
      bugsnagSpan.end();
    },
  }),
});

export const { useGetQuery } = dashboardApi;

export default dashboardApi;
