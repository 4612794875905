import { memo } from 'react';
// TODO: Card, CardBody... there is a mix between bootstrap classes and reacstrap components on our codebase

const ViewCampaignSection = ({ title, count }) => {
  return (
    <div className="col-lg col-md-6 col-sm-6 mb-4">
      <div className="stats-small stats-small--1 card card-small">
        <div className="card-body p-0 d-flex">
          <div className="d-flex flex-column m-auto">
            <div className="stats-small__data text-center">
              <span className="stats-small__label text-uppercase">{title}</span>
              <h6 className="stats-small__value count my-3">{count}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewCampaignSection);
