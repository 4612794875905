import React from 'react';
import { Input } from 'reactstrap';
import { useUpdateConfigMutation } from '@components/campaigns/campaignsApi';
import clsx from 'clsx';

const GraphButtons = ({ sectionKey, segmentKey, dataConfig, campaignId, className }) => {
  const [updateConfig, updateConfigStatus] = useUpdateConfigMutation();
  const featuredConfig = dataConfig['featured'][sectionKey];
  const exportConfig = dataConfig['pdf_export'][sectionKey];

  const handleExportClick = async () => {
    await updateConfig({
      campaign: { id: campaignId },
      config: {
        pdf_export: { ...dataConfig.pdf_export, [sectionKey]: exportConfig.includes(segmentKey) ? exportConfig.filter(i => i !== segmentKey) : [...exportConfig, segmentKey] },
        featured: { ...dataConfig.featured, [sectionKey]: exportConfig.includes(segmentKey) && featuredConfig.includes(segmentKey) ? [] : dataConfig.featured[sectionKey] },
      },
    });
  };

  const handleFeaturedClick = async () => {
    await updateConfig({
      campaign: { id: campaignId },
      config: {
        featured: { ...dataConfig.featured, [sectionKey]: featuredConfig.includes(segmentKey) ? [] : [segmentKey] },
      },
    });
  };

  return (
    <div className={clsx('position-absolute borders', className)} style={{ right: 0, top: 0 }}>
      <div className="d-flex align-items-center justify-content-end cursor-pointer" onClick={handleExportClick}>
        <p className="fw-bold" style={{ margin: '0 .25rem' }}>
          Include in PDF
        </p>
        <Input
          style={{ margin: '0 2px 0 0' }}
          type="checkbox"
          readOnly
          checked={dataConfig['pdf_export'][sectionKey].includes(segmentKey)}
          disabled={updateConfigStatus.isLoading}
        />
      </div>
      {dataConfig['pdf_export'][sectionKey].includes(segmentKey) && (
        <div className="d-flex align-items-center justify-content-end cursor-pointer" onClick={handleFeaturedClick}>
          <p className="fw-bold" style={{ margin: '0 .25rem' }}>
            Featured
          </p>
          <div className="">
            <i className="material-icons fs-6">{dataConfig['featured'][sectionKey].includes(segmentKey) ? 'star' : 'star_border'}</i>
          </div>
        </div>
      )}
    </div>
  );
};

export default GraphButtons;
