import '../assets/css/material-icon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/shards-dashboards.1.1.0.min.css';
import '../assets/css/extras.1.1.0.min.css';
import '../assets/css/custom.css';

import MainSidebar from '@elements/MainSidebar';
import TopBar from '@elements/TopBar';

const BaseLayout = ({ children, showSidebar = true, showTopBar = true }) => {
  return (
    <div className="d-flex" style={{ minHeight: '100vh' }}>
      {showSidebar && <MainSidebar />}
      <div style={{ flexGrow: 1 }}>
        {showTopBar && <TopBar />}
        {children}
      </div>
    </div>
  );
};

export default BaseLayout;
