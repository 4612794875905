import { Suspense, lazy, memo, useRef, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import TRANSLATIONS from '@json/en.json';
import Button from '@components/ButtonLoader';
import classes from './QEditor.module.scss';
import { constants } from '@utils';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ReactQuill = lazy(() => import('react-quill'));

const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'ShiftLeft', 'ShiftRight'];

const QuillEditor = ({ handleKeyDown, toggleEditable, isSaving, content, limitCharacters, handleChange, placeholder = '' }) => {
  const ref = useRef(null);

  const checkCharacterCount = event => {
    if (!(ref.current && limitCharacters)) {
      return false;
    }

    const editor = ref.current.getEditor();
    if (editor.getText().length - 1 > constants.QEDITOR_MAX_LENGTH && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const getCurrentCharCount = useCallback(ref => {
    if (!ref.current) {
      return 0;
    }

    return ref.current.getEditor().getText().length - 1;
  }, []);

  const progressColor = useMemo(() => {
    const percentage = getCurrentCharCount(ref) / constants.QEDITOR_MAX_LENGTH;

    if (percentage < 0.5) {
      return '#32a100'; // green
    }

    if (percentage < 0.75) {
      return '#ffb400'; // yellow
    }

    return '#ff0000'; // red
  }, [getCurrentCharCount, ref]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={classes.editorWrapper} onKeyDown={handleKeyDown}>
        <div className={classes.actionWrapper}>
          <Button onClick={() => toggleEditable(true)} loading={isSaving} className={clsx('btn btn-accent', classes.save)} type="submit">
            Save
          </Button>
          <i title={TRANSLATIONS.GENRIC.CLOSE} className={clsx('material-icons m-2 row-icons', isSaving && 'dim')} onClick={() => !isSaving && toggleEditable(false)}>
            close
          </i>
        </div>

        <ReactQuill
          value={content}
          onChange={handleChange}
          readOnly={isSaving}
          placeholder={placeholder}
          onKeyDown={checkCharacterCount}
          ref={ref}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
        />
        {limitCharacters && (
          <div className="d-flex justify-content-end align-items-center">
            <span>{getCurrentCharCount(ref) + '/' + constants.QEDITOR_MAX_LENGTH}</span>
            <div style={{ width: 40, height: 40, padding: 8 }}>
              <CircularProgressbar
                value={(getCurrentCharCount(ref) / constants.QEDITOR_MAX_LENGTH) * 100}
                styles={buildStyles({
                  pathColor: progressColor,
                })}
              />
            </div>
          </div>
        )}
      </div>
    </Suspense>
  );
};

export default memo(QuillEditor);
