import { useMemo } from 'react';
import { campaignBaseConfig } from '@utils';
import { useGetConfigQuery } from '@components/campaigns/campaignsApi';

const useDataConfig = (id, options = {}) => {
  const { data: configQueryData } = useGetConfigQuery(id, options);
  const dataConfig = useMemo(() => {
    if (!configQueryData) {
      return campaignBaseConfig;
    }

    return { ...campaignBaseConfig, ...configQueryData };
  }, [configQueryData]);

  return dataConfig;
};

export { useDataConfig };
