import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';

const loginApi = createApi({
  reducerPath: 'LoginApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({ url: `users/login`, method: 'POST', body }),
    }),
    get: builder.query({
      query: () => 'users/',
    }),
  }),
});

export const { useLoginMutation, useGetQuery } = loginApi;

export default loginApi;
