import { getToken } from '@utils';

export const prepareHeaders = (headers, { getState, endpoint }) => {
  if (endpoint === 'getTasks') {
    return headers;
  }

  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  const stateToken = getState().app && getState().app.user && getState().app.user.auth && getState().app.user.auth.token;
  const cookiesToken = getToken();

  const token = stateToken || cookiesToken;

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
