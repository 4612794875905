import { useState, useCallback } from 'react';
import './quill.snow.css';
import classes from './QEditor.module.scss';

import { showError } from '@components/Notifications';
import { extractTextContent } from '@utils';
import QuillEditor from './QuillEditor';

const QEditor = ({
  initialContent,
  updateContent,
  isSaving,
  placeholder,
  limitCharacters = true,
  placeholderStyle = {},
  textContentStyle = {},
  onChange = () => true,
  onUpdateEditable = () => true,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [content, setContent] = useState(initialContent);

  const handleChange = useCallback(
    value => {
      setContent(value);
      onChange && onChange(value);
    },
    [onChange]
  );

  const toggleEditable = useCallback(
    async shouldCommit => {
      const updateEditable = flag => {
        setIsEditable(flag);
        onUpdateEditable(flag);
      };

      if (typeof shouldCommit === 'boolean') {
        /* when we click on either save or close */
        if (shouldCommit) {
          try {
            await updateContent(content);
            updateEditable(false);
          } catch (e) {
            showError(`Error saving text!\n${e?.data?.message?.[0] ?? ''}`);
            updateEditable(true);
          }
        } else {
          updateEditable(false);
          setContent(initialContent);
        }
      } else {
        updateEditable(flag => !flag);
      }
    },
    [content, updateContent, initialContent, onUpdateEditable]
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
        toggleEditable(true);
      } else if (event.keyCode === 27) {
        toggleEditable(false);
      }
    },
    [toggleEditable]
  );

  return (
    <div className={classes.qEditor}>
      {isEditable ? (
        <QuillEditor {...{ handleKeyDown, toggleEditable, isSaving, limitCharacters, content, handleChange }} />
      ) : (
        <div onClick={toggleEditable}>
          {extractTextContent(initialContent)?.length > 0 ? (
            <div className={classes.textContent} dangerouslySetInnerHTML={{ __html: content }} style={textContentStyle}></div>
          ) : (
            <div className={classes.placeholderText} style={placeholderStyle}>
              {placeholder || 'Enter Graph Description'}
            </div>
          )}
          <i title={'Edit'} className={`material-icons m-2 row-icons ${classes.editIcon}`}>
            edit
          </i>
        </div>
      )}
    </div>
  );
};

export default QEditor;
