import { useEffect, useState, useMemo, useCallback } from 'react';
import useBus from 'use-bus';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import Button from '@components/ButtonLoader';
import { Row, Col, Progress } from 'reactstrap';
import { MdDownload } from 'react-icons/md';
import CreateReport from '@components/campaigns/CreateReport';
import T from '@json/en.json';
import { API_BASE_URL } from '@redux/apiEndPoint';
import { Link } from 'react-router-dom';
import { formatDate } from '@utils';
import { showSuccess } from '@components/Notifications';
import campaignsApi, { useGetByIdQuery, useGetConfigQuery } from '@components/campaigns/campaignsApi';
import clsx from 'clsx';

const PageHeader = ({ campaignData, maxUrls }) => {
  const campaignId = campaignData && campaignData.campaign ? campaignData.campaign.id : null;
  const dispatch = useDispatch();
  const { refetch } = useGetByIdQuery({ id: campaignId, params: { max_urls: maxUrls } });
  const { data: configData } = useGetConfigQuery(campaignId);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [csvDataLoading, setCsvDataLoading] = useState(false);
  const [csvDownloaded, setCsvDownloaded] = useState(false);

  useBus(
    'report-campaign-succeeded',
    ({ payload }) => {
      refetch();
      if (campaignId === payload.campaign_id) {
        showSuccess(`Report for Campaign ${campaignData.campaign.name} ready.`);
      }
    },
    []
  );
  useBus(
    'fetch-campaign-succeeded',
    ({ payload }) => {
      if (campaignData && campaignData.campaign && campaignData.campaign.id === payload.campaign_id) {
        showSuccess(`Success fetch for ${campaignData.campaign.name}`);
      }
    },
    []
  );

  useEffect(() => {
    setCsvData(null);
  }, [configData]);

  useEffect(() => {
    const interval = setInterval(refetch, 4000);

    if (campaignData && campaignData.campaign.status !== 1) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [campaignData, refetch]);

  const handleDownloadReportData = async () => {
    if (csvData && csvData.length) {
      return false;
    }

    setCsvDataLoading(true);

    const { ignore_exclusion_list: ignoreExclusionList, keywords = [] } = configData;

    const filters = {
      priority: keywords.filter(i => i.type === 'priority' && i.is_active).map(i => i.value),
    };

    if (!ignoreExclusionList) {
      filters.exclude_exact = keywords.filter(i => i.type === 'exclude_exact' && i.is_active).map(i => i.value);
      filters.exclude_containing = keywords.filter(i => i.type === 'exclude_containing' && i.is_active).map(i => i.value);
    }

    const { data } = await dispatch(campaignsApi.endpoints.getCsvExport.initiate({ id: campaignData.campaign.id, filters }));

    const output = [];

    if (data && data.length) {
      const csvHeader = Object.keys(data[0]);
      output.push(csvHeader);
      data.forEach(i => output.push(Object.values(i)));
    }

    setCsvData(output);
    setCsvDataLoading(false);
  };

  const inProgress = campaignData && campaignData.campaign.status === 1;
  const isFailure = campaignData && campaignData.campaign.status === 3;
  const isReady = campaignData && campaignData.campaign.status === 2;

  const buttonFormat = useMemo(() => {
    const data = {
      default: { text: 'New', color: 'secondary' },
      failure: { text: 'Failure', color: 'danger' },
      queue: { text: 'Queued', color: 'primary' },
      ready: { text: T.REPORT.CREATEREPORT, color: 'primary' },
      working: { text: 'Working...', color: 'primary' },
    };

    if (isFailure) {
      return data.failure;
    }
    if (inProgress && campaignData.campaign.busy_progress === 0) {
      return data.queue;
    }
    if (inProgress) {
      return data.working;
    }
    if (isReady) {
      return data.ready;
    }
    return data.default;
  }, [isFailure, inProgress, campaignData, isReady]);

  const isExportSelected = useMemo(() => {
    if (!configData || !configData.pdf_export) {
      return false;
    }

    if (!configData.pdf_export.share_of_voice || configData.pdf_export.share_of_voice.length < 1) {
      return false;
    }

    if (!configData.pdf_export.keyword_rankings || configData.pdf_export.keyword_rankings.length < 1) {
      return false;
    }

    if (!configData.pdf_export.keyword_distribution || configData.pdf_export.keyword_distribution.length < 1) {
      return false;
    }

    return true;
  }, [configData]);

  return (
    <>
      <Row className="page-header py-4">
        <Col xs="12" md="4">
          <h3 className="page-title mb-4">{T.CAMPAIGN.VIEWCAMPAIGN}</h3>
        </Col>
        <Col xs="12" md="8" className="d-flex justify-content-center justify-content-md-end flex-wrap my-auto">
          <Progress
            onClick={() => !inProgress && setModalIsOpen(i => !i)}
            value={inProgress ? campaignData.campaign.busy_progress * 100 : 100}
            animated={false}
            color={buttonFormat.color}
            className={clsx(!inProgress && 'cursor-pointer', 'mx-2 my-1 fw-bold', !isExportSelected && 'dim')}
            barStyle={{ height: 35, width: 120, overflow: 'visible' }}
            style={{ height: 35, width: 120, borderRadius: '0.25rem' }}
          >
            <div className={clsx('absolute', inProgress ? 'text-black' : 'text-white')} style={{ width: 120, fontSize: '0.75rem' }}>
              {buttonFormat.text}
            </div>
          </Progress>
          {campaignData.campaign && campaignData.campaign.token && campaignId && (
            <a
              href={`${API_BASE_URL}campaigns/${campaignId}/csv?t=${campaignData.campaign.token}`}
              className={clsx('btn btn-warning fw-bold mx-2 my-1', csvDownloaded && 'btn-disabled')}
              onClick={() => setCsvDownloaded(true)}
              download
            >
              {T.IDEA.DOWNLOAD_REPORT_DATA}
            </a>
          )}
          {campaignData.report_download && (
            <a
              href={`/media/${campaignData.report_download.report_url}`}
              className={clsx('btn btn-warning fw-bold mx-2 my-1', !campaignData.report_download.report_url && 'disabled')}
              download
            >
              <div className="text-dark d-flex align-items-center">
                <MdDownload className="icon-size" />
                <div className="me-2">{formatDate(campaignData.report_download.report_creation_date, 'MM/DD/YYYY')} Report</div>
              </div>
            </a>
          )}

          <Link to="/campaigns" className="btn btn-danger fw-bold mx-2 my-1 text-white">{` <<  ${T.GENRIC.BACK}`}</Link>
        </Col>
      </Row>
      {modalIsOpen && <CreateReport onSuccess={refetch} campaign={campaignData.campaign} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} />}
    </>
  );
};

export default PageHeader;
