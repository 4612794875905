import { Fragment, useState } from 'react';
import T from '@json/en.json';
import Button from '@components/ButtonLoader';
import { Label, Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useAddUrlMutation } from '@components/campaigns/campaignsApi';
import { showError, showSuccess } from '@components/Notifications';

const initialFormData = {
  client_urls: '',
  control_urls: '',
};

const AddUrlModal = ({ setIsModalOpen, isModalOpen, campaignId }) => {
  const [addUrlMutation, { isLoading }] = useAddUrlMutation();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const onFormSubmit = async e => {
    e.preventDefault();
    const { error, data } = await addUrlMutation({ id: campaignId, ...formData });

    if (error) {
      let message;
      if (typeof error.error === 'string') {
        message = error.error;
        showError(message);
      } else {
        const invalid_urls = error.data?.error_client_urls?.concat(error.data?.error_control_urls ?? []).filter(e => e) ?? [];
        const duplicate_urls = error.data?.duplicate_client_urls?.concat(error.data?.duplicate_control_urls ?? []).filter(e => e) ?? [];
        const invalid_message = invalid_urls.length > 0 ? `\n\nFollowing are invalid urls\n${invalid_urls.join(', ')}` : '';
        const duplicate_message = duplicate_urls.length > 0 ? `\n\n Following urls already exists: \n${duplicate_urls.join(', ')}` : '';
        message = (error.data.message || '') + invalid_message + duplicate_message;
      }
      return setError(message);
    }
    setError('');
    showSuccess(data.message);
    setIsModalOpen(false);
    setFormData(initialFormData);
  };
  const handleFormChange = ({ target }) => {
    setFormData(i => ({ ...i, [target.name]: target.value }));
  };
  return (
    <Modal size="lg" scrollable isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
      <ModalHeader>
        <b>Add URL</b>
      </ModalHeader>
      <form onSubmit={onFormSubmit}>
        <ModalBody>
          <div className="row mb-2 add-url-description">
            To add new URLs to the campaign or control group, add them in the boxes below. Once you click ‘Add & Fetch’, data for these URLs will be collected from our data
            partners and the URLs and data will be added to the report.
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <Label>{T.CAMPAIGN.FIELD_NAMES.CLIENT_URLS}</Label>
                <Input
                  name="client_urls"
                  placeholder={T.CAMPAIGN.FIELD_NAMES.ADD_URL_PLACEHOLDER}
                  className="form-control"
                  type="textarea"
                  disabled={isLoading}
                  onChange={handleFormChange}
                  value={formData.client_urls}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label>{T.CAMPAIGN.FIELD_NAMES.CONTROL_URLS}</Label>
                <Input
                  name="control_urls"
                  placeholder={T.CAMPAIGN.FIELD_NAMES.ADD_URL_PLACEHOLDER}
                  className="form-control"
                  type="textarea"
                  disabled={isLoading}
                  onChange={handleFormChange}
                  value={formData.control_urls}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="background-footer-model ">
          {error && <p className="text-danger m-2 pre-line full-width">{error}</p>}
          <Button className="btn btn-outline-secondary m-2 cancel-bg" name="btn" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button className="btn btn-accent m-2" name="btn" type="submit" disabled={!formData.client_urls && !formData.control_urls} loading={isLoading}>
            Add & Fetch
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const AddUrl = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <div className="btn-container">
        <Button color="primary" onClick={() => setIsModalOpen(true)}>
          Add URL
        </Button>
      </div>
      <AddUrlModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} {...props} />
    </Fragment>
  );
};

export default AddUrl;
