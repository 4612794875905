import { memo, useEffect } from 'react';
import { CardHeader, Table, Col, Card, CardBody, Spinner } from 'reactstrap';
import { useGetKeywordsRankingQuery } from '@components/campaigns/campaignsApi';
import { getFiltersFor, formatPercentChange } from '@utils';
import { useDataConfig } from '@hooks/dataConfig';

const CampaignKeywordRankingsTable = ({ campaignId, onLoadEnd }) => {
  const dataConfig = useDataConfig(campaignId);

  const { data: keywordRankingsData, isFetching, status } = useGetKeywordsRankingQuery({ id: campaignId, filters: getFiltersFor(null, dataConfig) });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  if (isFetching) {
    return (
      <div className="d-flex align-items-center my-6">
        <Spinner size="sm" className="mx-1" />
        <p className="mx-1 my-0">Loading table...</p>
      </div>
    );
  }

  if (!keywordRankingsData) {
    return null;
  }

  const { metrics = {}, top_keywords, rank_avg_monthwise, month_keys } = keywordRankingsData;

  return (
    <Col>
      <Card className="mb-4">
        <CardBody className="p-0 pb-3">
          <DiffTable metrics={metrics} month_keys={month_keys} rank_avg_monthwise={rank_avg_monthwise} />
          <PositionTable top_keywords={top_keywords} />
        </CardBody>
      </Card>
    </Col>
  );
};

const DiffTable = ({ metrics: { client, control, yy_diff, mm_diff, pp_diff }, rank_avg_monthwise, month_keys }) => {
  if (!month_keys || !month_keys.length || !client || !control) {
    return null;
  }

  const keyLength = month_keys.length;
  const currentMonth = month_keys[keyLength - 1];
  const previousMonth = month_keys[keyLength - 2];
  const firstMonth = month_keys[0];
  const showY = Boolean(client.yy_average || control.yy_average);

  const clientAvgPpYy = client[showY ? 'yy_average' : 'pp_average'];
  const controlAvgPpYy = control[showY ? 'yy_average' : 'pp_average'];

  return (
    <Table className="table mb-0">
      <thead>
        <tr>
          <th>Segment</th>
          {firstMonth && firstMonth != previousMonth && <th scope="col">{firstMonth}</th>}
          {previousMonth && <th scope="col">{previousMonth}</th>}
          <th>{currentMonth}</th>
          <th>M/M</th>
          <th>% diff</th>
          <th>{showY ? 'Y/Y' : 'P/P'}</th>
          <th>% Diff</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Campaign</td>
          {firstMonth && firstMonth != previousMonth && <td scope="col">{rank_avg_monthwise?.client[firstMonth] || ''}</td>}
          {previousMonth && <td scope="col">{rank_avg_monthwise?.client[previousMonth] || ''}</td>}
          <td>{rank_avg_monthwise?.client[currentMonth] || ''}</td>
          <td>{formatPercentChange(client.mm_average)}</td>
          <td>{mm_diff}%</td>
          <td>{formatPercentChange(clientAvgPpYy)}</td>
          <td>{`${showY ? yy_diff : pp_diff}%`}</td>
        </tr>
        <tr>
          <td>Control</td>
          {firstMonth && firstMonth != previousMonth && <td scope="col">{rank_avg_monthwise?.control[firstMonth] || ''}</td>}
          {previousMonth && <td scope="col">{rank_avg_monthwise?.control[previousMonth] || ''}</td>}
          <td>{rank_avg_monthwise?.control[currentMonth] || ''}</td>
          <td>{formatPercentChange(control.mm_average)}</td>
          <td></td>
          <td>{formatPercentChange(controlAvgPpYy)}</td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};

const PositionTable = ({ top_keywords = [] }) => {
  return (
    <>
      <CardHeader className="border-bottom mt-4">
        <h6 className="m-0">Keyword Rankings - Top 10</h6>
      </CardHeader>

      <Table className="table">
        <thead>
          <tr>
            <th>Keyword</th>
            <th>Searches</th>
            <th>Position</th>
            <th>SOV</th>
          </tr>
        </thead>
        <tbody>
          {top_keywords.map((kw, index) => {
            const keyword = kw[0] || {};

            return (
              <tr key={index}>
                <td>{keyword.keyword}</td>
                <td>{keyword.search_volume}</td>
                <td>{keyword.position}</td>
                <td>{kw[2]}%</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default memo(CampaignKeywordRankingsTable);
