import { createSlice } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import { storeStateAtLocalStorage, getStateFromLocalStorage } from '@utils';

const user = JSON.parse(cookies.get('user') || '{}');

const initialState = {
  user: user || { id: '', name: '', email: '', auth: { token: '', iat: 0, exp: 0 } },
  settings: {
    isSidebarOpen: true,
    allCampaigns: {
      columns: {
        country_flag: {
          visible: true,
          order: 0,
        },
        name: {
          visible: true,
          order: 1,
        },
        data_date_range: {
          visible: true,
          order: 2,
        },
        report_date_range: {
          visible: true,
          order: 3,
        },
        last_updated: {
          visible: true,
          order: 4,
        },
        status: {
          visible: true,
          order: 5,
        },
        users: {
          visible: true,
          order: 6,
        },
        inactive: {
          visible: true,
          order: 7,
        },
      },
      hideInactive: false,
    },
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState: { ...initialState, ...getStateFromLocalStorage('appSlice') },
  reducers: {
    toggleSidebar({ settings }) {
      settings.isSidebarOpen = !settings.isSidebarOpen;
      storeStateAtLocalStorage('appSlice', { settings });
    },
    toggleHideInactive({ settings }) {
      settings.allCampaigns.hideInactive = !settings.allCampaigns.hideInactive;
      storeStateAtLocalStorage('appSlice', { settings });
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserToken(state, action) {
      state.user.auth.token = action.payload;
    },
    toggleColumnVisibility({ settings }, { payload }) {
      const columnName = payload.column;
      settings.allCampaigns.columns[columnName].visible = !settings.allCampaigns.columns[columnName].visible;
      storeStateAtLocalStorage('appSlice', { settings });
    },
  },
});

export const { toggleSidebar, setUser, setUserToken, toggleColumnVisibility, toggleHideInactive } = appSlice.actions;

export default appSlice;
