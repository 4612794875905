import { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import QEditor from '@elements/QEditor/QEditor';
import { useGetLinksQuery } from '@components/campaigns/campaignsApi';
import clsx from 'clsx';

const CampaignLinksChart = ({ graph_data, updateContent, isSaving, campaignId }) => {
  const { data, isFetching } = useGetLinksQuery(campaignId);
  const linksData = data || {};

  const builtList = useMemo(() => Object.values(linksData.dates || {}), [linksData.dates]);

  const chr = {
    options: {
      chart: {
        id: 'links-building',
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: linksData.dates ? Object.keys(linksData.dates) : [],
        tickAmount: 6,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },

          axisBorder: {
            show: true,
            color: '#b2b4b8',
          },
          labels: {
            style: {
              colors: '#b2b4b8',
            },
          },
          title: {
            text: 'Links Built',
            style: {
              color: '#b2b4b8',
            },
          },
        },
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: 0,
        dashArray: 0,
      },
    },
    series: [
      {
        name: 'Links Built',
        data: builtList,
        type: 'column',
      },
    ],
    fill: {
      type: 'solid',
      colors: ['#B32824'],
    },
  };

  const datesLength = linksData.dates ? Object.keys(linksData.dates).length : 0;

  const highchartsOptions = {
    title: { text: null },
    xAxis: [{ categories: chr.options.xaxis.categories, crosshair: true }],
    yAxis: chr.options.yaxis,
    series: chr.series,
    plotOptions: chr.options.plotOptions || {},
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="w-100 mw-100">
          <QEditor isSaving={isSaving} initialContent={graph_data?.link_building?.description || ''} updateContent={content => updateContent(content, 'link_building')} />
        </div>
      </div>
      {datesLength > 0 && (
        <div className="w-100">
          <HighchartsReact containerProps={{ style: { maxHeight: 480 }, className: clsx('mx-auto', isFetching && 'dim') }} highcharts={Highcharts} options={highchartsOptions} />
        </div>
      )}
      {datesLength === 0 && (
        <div>
          <p className="text-center mt-3 mb-0">No Placements found for any Campaign URL in this Campaign.</p>
          <p className="text-center">Linkcount Document is required to calculate Placements.</p>
        </div>
      )}
    </div>
  );
};
export default CampaignLinksChart;
