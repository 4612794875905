import { Redirect, useHistory } from 'react-router-dom';
import { signOut } from '@utils';

import BaseLayout from '../layouts/Base';

import Login from '@components/login';

import Dashboard from '@components/dashboard';

import AllCampaign from '@components/campaigns';
import AddCampaign from '@components/campaigns/add';
import EditCampaign from '@components/campaigns/edit';
import ViewCampaign from '@components/campaigns/view';
import ViewUrl from '@components/urls/ViewUrl';

import AllLinkcountDocument from '@components/linkcountdocuments';
import AddLinkcountDocument from '@components/linkcountdocuments/add';

import AllReport from '@components/reports';

import AllSummary from '@components/summary';

export default [
  {
    path: '/',
    exact: true,
    layout: i => <BaseLayout {...i} showSidebar={false} showTopBar={false} />,
    component: () => <Redirect to="/login" />,
  },
  {
    path: '/login',
    exact: true,
    layout: i => <BaseLayout {...i} showSidebar={false} showTopBar={false} />,
    component: Login,
  },
  {
    path: '/logout',
    exact: true,
    layout: i => <BaseLayout {...i} showSidebar={false} showTopBar={false} />,
    component: () => {
      const history = useHistory();

      signOut();
      history.push('/login?reload=true');

      return <p className="my-1 mx-2">Redirecting...</p>;
    },
  },
  {
    path: '/dashboard',
    exact: true,
    layout: BaseLayout,
    component: Dashboard,
  },
  {
    path: '/campaigns',
    exact: true,
    layout: BaseLayout,
    component: AllCampaign,
  },
  {
    path: '/campaigns/add',
    exact: true,
    layout: BaseLayout,
    component: AddCampaign,
  },
  {
    path: '/campaigns/:id/edit',
    exact: true,
    layout: BaseLayout,
    component: EditCampaign,
  },
  {
    path: '/campaigns/:id/view',
    exact: true,
    layout: BaseLayout,
    component: ViewCampaign,
  },
  {
    path: '/campaigns/:id/reports',
    exact: true,
    layout: BaseLayout,
    component: AllReport,
  },
  {
    path: '/urls/:id',
    exact: true,
    layout: BaseLayout,
    component: ViewUrl,
  },
  {
    path: '/linkcountdocuments',
    exact: true,
    layout: BaseLayout,
    component: AllLinkcountDocument,
  },
  {
    path: '/linkcountdocuments/add',
    exact: true,
    layout: BaseLayout,
    component: AddLinkcountDocument,
  },
  {
    path: '/reports',
    exact: true,
    layout: BaseLayout,
    component: AllReport,
  },
  {
    path: '/summaries',
    exact: true,
    layout: BaseLayout,
    component: AllSummary,
  },
  {
    /* eslint-disable @typescript-eslint/no-unused-vars  */
    layout: ({ staticContext, ...i }) => {
      return <div {...i} className="error-position"></div>;
    },
    component: () => (
      <a href="/" className="no-route">
        404 | Not Found
      </a>
    ),
  },
];
