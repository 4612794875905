import { Table } from 'reactstrap';
import { useGetLinksQuery } from '@components/campaigns/campaignsApi';
import { Spinner, Col, Card, CardHeader, CardBody } from 'reactstrap';

const CampaignLinkBuildingTable = ({ campaignId }) => {
  const { data: linksData, isFetching } = useGetLinksQuery(campaignId);

  if (isFetching) {
    return (
      <div className="text-center mt-3">
        <Spinner />
      </div>
    );
  }

  if (!linksData || Object.keys(linksData.monthly).length === 0) {
    return (
      <div>
        <p className="text-center mt-3 mb-0">No links were built this month.</p>
      </div>
    );
  }

  return (
    <Col>
      <Card className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Links built this month</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <Table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col">URL</th>
                <th scope="col">Links Built</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(linksData.monthly).map(([key, value]) => {
                return (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default CampaignLinkBuildingTable;
