import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '@components/ButtonLoader';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import T from '@json/en.json';
import countries from '@json/countries.json';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { Container, Label, Row, Col, Input, Form } from 'reactstrap';
import { useAddMutation, useGetLinkLaunchURLsQuery } from '@components/campaigns/campaignsApi';
import { formatDate, campaignBaseConfig } from '@utils';
import { showSuccess } from '@components/Notifications';

const subfolderKeywordLimitDefault = 5000;
const countryList = Object.keys(countries).map(i => ({ value: countries[i].value, label: `${getUnicodeFlagIcon(countries[i].value)} ${countries[i].label}` }));

const normalCampaignRequiredFields = ['name', 'keyword_limit', 'report_graph_start', 'report_graph_end', 'client_urls', 'control_urls', 'country', 'normal_subfolder'];
const subfolderCampaignRequiredFields = [
  'name',
  'keyword_limit',
  'subfolder_keyword_limit',
  'report_graph_start',
  'report_graph_end',
  'client_urls',
  'control_urls',
  'country',
  'normal_subfolder',
];

const requiredFields = {
  1: normalCampaignRequiredFields,
  2: subfolderCampaignRequiredFields,
};
const keywordLimitList = [10, 25, 100, 500, 1000, 5000].map(e => ({ value: e, label: e, id: e }));

const radioButtonsList = [
  { id: 1, name: T.CAMPAIGN.FIELD_NAMES.NORMAL },
  { id: 2, name: T.CAMPAIGN.FIELD_NAMES.SUBFOLDER },
];

const formInitialState = {
  name: '',
  keyword_limit: 1000,
  subfolder_keyword_limit: '',
  report_graph_start: null,
  report_graph_end: null,
  client_urls: '',
  control_urls: '',
  country: 'us',
  normal_subfolder: 1,
};

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const AddCampaign = () => {
  const history = useHistory();
  const query = useQuery();
  const [formData, setFormData] = useState(formInitialState);
  const [formDataError, setFormDataError] = useState({});
  const [addMutation, { isLoading }] = useAddMutation();
  const { data: LLURLs } = useGetLinkLaunchURLsQuery({ auth: query.get('auth') });

  const getLinks = useCallback(async () => {
    if (LLURLs?.data.urls) {
      setFormData(i => ({ ...i, client_urls: LLURLs.data.urls.campaign.join(), control_urls: LLURLs.data.urls.control.join() }));
    }
    if (LLURLs?.data.keywords) {
      setFormData(i => ({ ...i, keywords_priority: LLURLs.data.keywords.join() }));
    }
  }, [LLURLs]);

  useEffect(() => {
    getLinks();
  }, [getLinks]);

  const onFormSubmit = async e => {
    e.preventDefault();

    setFormDataError({});

    for (const field of requiredFields[formData.normal_subfolder]) {
      if (!formData[field]) {
        setFormDataError(i => ({ ...i, [field]: true }));
      }
    }

    if (!requiredFields[formData.normal_subfolder].every(i => formData[i])) {
      return false;
    }

    const formatKeywordsArray = (input = '', type = '') => {
      if (!input || !type) {
        return [];
      }

      return [
        ...new Set(
          input
            .split(/[\t\n,]+/)
            .map(i => i.trim())
            .filter(Boolean)
        ),
      ].map(i => ({ value: i, type, is_active: true }));
    };

    const keywords = [
      ...formatKeywordsArray(formData.keywords_exclude_exact, 'exclude_exact'),
      ...formatKeywordsArray(formData.keywords_exclude_containing, 'exclude_containing'),
      ...formatKeywordsArray(formData.keywords_priority, 'priority'),
    ];

    const campaignData = {
      client_urls: formData.client_urls,
      config: {
        ...campaignBaseConfig,
        keywords,
        filters: {
          all_tracked: true,
          top_10: true,
          top_25: true,
          top_50: true,
          top_1000: false,
          position_lte_20: true,
          position_lte_10: true,
          position_1to3: true,
          priority: true,
        },
      },
      control_urls: formData.control_urls,
      country: formData.country,
      keyword_limit: formData.keyword_limit,
      name: formData.name,
      normal_subfolder: formData.normal_subfolder,
      report_graph_end: formatDate(formData.report_graph_end, 'YYYY-MM-15'),
      report_graph_start: formatDate(formData.report_graph_start, 'YYYY-MM-15'),
      subfolder_keyword_limit: formData.subfolder_keyword_limit || subfolderKeywordLimitDefault,
    };

    const { data, error } = await addMutation(campaignData);

    if (error) {
      return false;
    }

    showSuccess(data.message);

    history.push('/campaigns');
  };

  const handleStartDateChange = date => {
    setFormData(i => ({ ...i, report_graph_start: date }));
  };

  const handleEndDateChange = date => {
    setFormData(i => ({ ...i, report_graph_end: date }));
  };

  const handleFormChange = ({ target }) => {
    setFormData(i => ({ ...i, [target.name]: target.value }));
  };

  const handleSelectChange = (item, dropdown) => {
    setFormData(i => ({ ...i, [dropdown.name]: item.value }));
  };

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <h3 className="page-title">
            {T.CAMPAIGN.ADDCAMPAIGN}
            <Link to="/campaigns" className={clsx(isLoading && 'btn-disabled', 'btn btn-sm btn-danger back-button pull-right')}>
              {` << ${T.GENRIC.BACK}`}
            </Link>
          </h3>
        </Col>
      </Row>

      <div className="card card-default">
        <div className="card-body">
          <Form id="add-campaign-form" onSubmit={onFormSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.CAMPAIGN_NAME}
                    {!formData.name && <span className="form-error">*</span>}
                  </Label>

                  <Input
                    disabled={isLoading}
                    name="name"
                    type="text"
                    placeholder={T.CAMPAIGN.FIELD_NAMES.CAMPAIGN_NAME}
                    className="form-control"
                    onChange={handleFormChange}
                    value={formData.name}
                  />

                  {formDataError.name && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.FETCH_START_DATE}
                    {!formData.report_graph_start && <span className="form-error">*</span>}
                  </Label>

                  <DatePicker
                    disabled={isLoading}
                    showMonthYearPicker
                    selected={formData.report_graph_start}
                    name="start_date"
                    className="form-control"
                    dateFormat="MMMM yyyy"
                    placeholderText="MM-YYYY"
                    autoComplete="off"
                    maxDate={new Date()}
                    onChange={handleStartDateChange}
                  />
                  {formDataError.report_graph_start && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.FETCH_END_DATE}
                    {!formData.report_graph_end && <span className="form-error">*</span>}
                  </Label>

                  <DatePicker
                    disabled={isLoading}
                    showMonthYearPicker
                    selected={formData.report_graph_end}
                    name="end_date"
                    className="form-control"
                    dateFormat="MMMM yyyy"
                    placeholderText="MM-YYYY"
                    maxDate={new Date()}
                    minDate={formData.report_graph_start}
                    autoComplete="off"
                    onChange={handleEndDateChange}
                  />
                  {formDataError.report_graph_end && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
              <i className="campaigns-infor">{T.CAMPAIGN.DATE_RANGE_INFO}</i>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.KEYWORD_LIMIT}
                    {!formData.keyword_limit && <span className="form-error">*</span>}
                  </Label>

                  <Select
                    isDisabled={isLoading}
                    name="keyword_limit"
                    defaultValue={keywordLimitList.find(e => e.value === 1000)}
                    placeholder={T.CAMPAIGN.FIELD_NAMES.SELECT_KEYWORD_LIMIT}
                    onChange={handleSelectChange}
                    isMulti={false}
                    options={keywordLimitList}
                    maxMenuHeight={150}
                  />
                  {formDataError.keyword_limit && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className={clsx(formData.normal_subfolder == 1 && 'opacity-40', 'form-group')}>
                  <Label title={formData.normal_subfolder != 1 ? 'Required' : ''}>
                    {T.CAMPAIGN.FIELD_NAMES.SUBFOLDER_LIMIT}
                    {formData.normal_subfolder != 1 && !formData.subfolder_keyword_limit && <span className="form-error">*</span>}
                  </Label>
                  <Select
                    isDisabled={isLoading || formData.normal_subfolder == 1}
                    name="subfolder_keyword_limit"
                    placeholder={T.CAMPAIGN.FIELD_NAMES.SELECT_SUBFOLDER_LIMIT}
                    onChange={handleSelectChange}
                    isMulti={false}
                    options={keywordLimitList}
                    maxMenuHeight={150}
                  />
                  {formDataError.subfolder_keyword_limit && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.CLIENT_URLS}
                    {!formData.client_urls && <span className="form-error">*</span>}
                  </Label>
                  <Input
                    disabled={isLoading}
                    name="client_urls"
                    placeholder={T.CAMPAIGN.FIELD_NAMES.TEXT_CLIENT_URLS}
                    className="form-control"
                    type="textarea"
                    onChange={handleFormChange}
                    value={formData.client_urls}
                  />

                  {formDataError.client_urls && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required">
                    {T.CAMPAIGN.FIELD_NAMES.CONTROL_URLS}
                    {!formData.control_urls && <span className="form-error">*</span>}
                  </Label>
                  <Input
                    disabled={isLoading}
                    name="control_urls"
                    placeholder={T.CAMPAIGN.FIELD_NAMES.TEXT_CONTROL_URLS}
                    className="form-control"
                    type="textarea"
                    onChange={handleFormChange}
                    value={formData.control_urls}
                  />

                  {formDataError.control_urls && <span className="form-error">{T.ERRORS.EMPTY}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label title="Required">
                  {T.CAMPAIGN.FIELD_NAMES.COUNTRY}
                  {!formData.country && <span className="form-error">*</span>}
                </Label>
                <Select
                  isDisabled={isLoading}
                  name="country"
                  defaultValue={countryList.find(e => e.value === 'us')}
                  placeholder={T.CAMPAIGN.FIELD_NAMES.COUNTRY}
                  onChange={handleSelectChange}
                  isMulti={false}
                  options={countryList}
                  maxMenuHeight={150}
                />
                {formDataError.country && <span className="form-error">{T.ERRORS.EMPTY}</span>}
              </div>
            </div>
            <div className="row mx-2 mb-4">
              {radioButtonsList.map(option => {
                return (
                  <div key={option.id} className="inline-radio" onClick={() => !isLoading && setFormData(i => ({ ...i, normal_subfolder: option.id }))}>
                    <Input disabled={isLoading} type="radio" checked={option.id === formData.normal_subfolder} readOnly />
                    <Label className="ms-2">{option.name}</Label>
                  </div>
                );
              })}
              {formDataError.normal_subfolder && <span className="form-error">{T.ERRORS.EMPTY}</span>}
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Exclude Keywords containing:</Label>
                  <Input
                    disabled={isLoading}
                    name="keywords_exclude_containing"
                    placeholder="Add Keywords..."
                    className="form-control"
                    type="textarea"
                    onChange={handleFormChange}
                    value={formData.keywords_exclude_containing}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Exclude Keywords exactly matching:</Label>
                  <Input
                    disabled={isLoading}
                    name="keywords_exclude_exact"
                    placeholder="Add Keywords..."
                    className="form-control"
                    type="textarea"
                    onChange={handleFormChange}
                    value={formData.keywords_exclude_exact}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <Label>Include Priority Keywords:</Label>
                  <Input
                    disabled={isLoading}
                    name="keywords_priority"
                    placeholder="Add Keywords..."
                    className="form-control"
                    type="textarea"
                    onChange={handleFormChange}
                    value={formData.keywords_priority}
                  />
                </div>
              </div>
            </div>
            <Button loading={isLoading} className="btn btn-accent" type="submit">
              {T.GENRIC.SUBMIT}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default AddCampaign;
