import T from '@json/en.json';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { useGetQuery } from './dashboardApi';

const Dashboard = () => {
  const { data = {}, isLoading } = useGetQuery();

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <span className="text-uppercase page-subtitle">{T.OVERVIEW.TITILE}</span>
          <h3 className="page-title mt-3">{T.DASHBOARD.TITILE}</h3>
        </Col>
      </Row>
      <Row>
        <div className="col mb-4">
          <div className="stats-small stats-small--1 card card-small">
            <div className="card-body p-0 d-flex">
              <div className="d-flex flex-column m-auto">
                <div className="stats-small__data text-center">
                  {isLoading ? (
                    <Spinner className="my-1 mx-auto" />
                  ) : (
                    <>
                      <span className="stats-small__label text-uppercase">{T.DASHBOARD.CAMPAIGNS}</span>
                      <h6 className="stats-small__value count my-3">{data.campaign_count}</h6>
                    </>
                  )}
                </div>
              </div>
              <canvas height="120" className="blog-overview-stats-small-1" />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Dashboard;
