import { memo, useEffect, Fragment } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { constants } from '@utils';
import { useGetKeywordsDistributionQuery } from '@components/campaigns/campaignsApi';
import { Spinner } from 'reactstrap';

const Header = () => {
  return (
    <thead className="bg-light">
      <tr>
        <th scope="col">URL</th>
        {constants.KEYWORD_DISTRIBUTION_SEGEMENTS.map(i => (
          <th key={i.slug} scope="col">
            Rank: <br />
            {i.text}
          </th>
        ))}
      </tr>
    </thead>
  );
};
const THead = memo(Header);

const renderRow = (row = {}) => {
  return (
    <tr key={row.url?.id}>
      <td>{row.url?.url ?? ''}</td>
      {constants.KEYWORD_DISTRIBUTION_SEGEMENTS.map(i => (
        <td key={i.slug}>{row[i.slug]}</td>
      ))}
    </tr>
  );
};

const TableSection = ({ dataArray, label }) => {
  return (
    <Row>
      <Col>
        <Card className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{label}</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <Table className="table mb-0">
              <THead />
              <tbody>{dataArray.map(renderRow)}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const KeywordDistributionTable = ({ campaignId, onLoadEnd }) => {
  const { data, isFetching, status } = useGetKeywordsDistributionQuery({ id: campaignId });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  if (isFetching) {
    return (
      <div className="d-flex align-items-center my-6">
        <Spinner size="sm" className="mx-1" />
        <p className="mx-1 my-0">Loading table...</p>
      </div>
    );
  }

  if (data?.url_distribution?.length > 9) {
    return (
      <Fragment>
        <TableSection dataArray={data?.url_distribution.slice(0, 5) ?? []} label={'Top Box'} />
        <TableSection dataArray={data?.url_distribution.slice(-5) ?? []} label={'Bottom Box'} />
      </Fragment>
    );
  }

  return <TableSection dataArray={data?.url_distribution ?? []} label={'Distribution by Page'} />;
};

export default KeywordDistributionTable;
