import { useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Table, Spinner, Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { formatDate } from '@utils';
import { useGetQuery, useDeleteMutation } from '@components/reports/reportsApi';
import TRANSLATIONS from '@json/en.json';
import DeleteConfirmationModal from '@components/DeleteConfirmationModal';
import { showSuccess, showError } from '@components/Notifications';
import { ucFirst } from '@utils';
import useBus from 'use-bus';

const AllReport = () => {
  const { id } = useParams();
  const { data = {}, isLoading, refetch } = useGetQuery({ campaign: { id } });
  const [deleteMutation] = useDeleteMutation();
  const [selectedReportId, setSelectedReportId] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useBus(
    ['report-campaign-succeeded'],
    ({ type }) => {
      if (type === 'report-campaign-succeeded') {
        refetch();
        showSuccess(`Report created.`);
      }
    },
    []
  );

  const handleDeleteConfirm = async (value, confirmValue) => {
    setIsDeleteModalOpen(value);
    if (confirmValue === 'yes') {
      const response = await deleteMutation({ id: selectedReportId });

      if (response.error) {
        return showError("Can't delete Document");
      }

      showSuccess('Report deleted.');
    }
  };

  const handleLinkDelete = ({ id }) => {
    setSelectedReportId(id);
    setIsDeleteModalOpen(true);
  };

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <span className="text-uppercase page-subtitle">{TRANSLATIONS.OVERVIEW.TITILE}</span>
          <h3 className="page-title mt-3">
            <span className="mr-3">{TRANSLATIONS.REPORT.ALLREPORT}</span>
            {id && (
              <Link to="/campaigns" className="btn btn-sm btn-danger back-button pull-right">
                {` << ${TRANSLATIONS.GENRIC.BACK}`}
              </Link>
            )}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
          {!isLoading && (
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{TRANSLATIONS.REPORT.ALLREPORT}</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Table responsive>
                  <thead className="bg-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{TRANSLATIONS.REPORT.FIELD_NAMES.NAME}</th>
                      <th scope="col">{TRANSLATIONS.REPORT.FIELD_NAMES.DESCRIPTION}</th>
                      <th scope="col">{TRANSLATIONS.REPORT.FIELD_NAMES.CREATED_DATE}</th>
                      <th scope="col">{TRANSLATIONS.REPORT.FIELD_NAMES.USERS}</th>
                      <th scope="col">{TRANSLATIONS.REPORT.FIELD_NAMES.ACTIONS}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.results &&
                      data.results.map((i, index) => {
                        if (!i.pdf) {
                          return null;
                        }
                        return (
                          <tr key={i.id}>
                            <td>{index + 1}</td>
                            <td>
                              {i.pdf ? (
                                <a className="link cursor-pointer" href={i.pdf} download>
                                  {i.report_title || i.name}
                                </a>
                              ) : (
                                <span>{i.report_title || i.name}</span>
                              )}
                            </td>
                            <td>{i.description}</td>
                            <td>{formatDate(i.created_date, 'MM/DD/YYYY')}</td>
                            <td>{i.users.map(user => ucFirst(user.username)).join(', ')}</td>
                            <td>
                              <Button className="mb-2 btn btn-danger ms-2" onClick={() => handleLinkDelete(i)}>
                                {TRANSLATIONS.GENRIC.DELETE}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {data.results && data.results.length === 0 && (
                  <div>
                    <div className="no-data">{TRANSLATIONS.GENRIC.NO_RECORDS_FOUND}</div>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} handleDeleteConfirm={handleDeleteConfirm} title={TRANSLATIONS.REPORT.DELETE} />}
    </Container>
  );
};

export default AllReport;
