import React, { useState } from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useGetQuery } from '@components/login/loginApi';
import { ucFirst } from '@utils';

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const sortedValues = values => {
  return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

const UsersDropdown = ({ onSave, onCancel, users, ...props }) => {
  const authUser = useSelector(state => state.app.user);
  const currentUser = users.find(i => i.id === authUser.id) || authUser;

  const usersMapper = i => ({ id: i.id, value: i.id, label: ucFirst(i.username), isFixed: i.id === authUser.id, role: i.role });
  const [items, setItems] = useState(sortedValues(users.map(usersMapper)));

  const { data } = useGetQuery();
  const allUsers = data ? data.results.map(usersMapper) : [];

  const onChange = (values, actionMeta, role) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        values = values.filter(v => v.isFixed);
        break;
    }

    values = sortedValues(values);
    setItems(items => items.filter(i => i.role !== role).concat(values.map(i => ({ ...i, role }))));
  };
  const handleCancel = () => {
    onCancel && onCancel(items);
  };

  const handleSave = () => {
    onSave && onSave(items);
  };

  return (
    <div {...props}>
      <div>
        <div className="text-center fw-bolder">
          {users
            .filter(i => i.role === 'creator')
            .map(i => ucFirst(i.username))
            .join(' ')}
        </div>
        <p className="mb-0">Owners:</p>
        <Select
          className="mb-1"
          value={items.filter(i => i.role === 'owner')}
          isMulti
          styles={styles}
          isClearable={items.some(v => !v.isFixed)}
          name="owners"
          classNamePrefix="select"
          onChange={(value, actionMeta) => onChange(value, actionMeta, 'owner')}
          options={allUsers.filter(user => !items.find(i => i.value === user.value))}
          isDisabled={!['owner', 'creator'].includes(currentUser.role)}
        />
      </div>
      <div>
        <p className="mb-0">Managers:</p>
        <Select
          className="mb-1"
          value={items.filter(i => i.role === 'manager')}
          isMulti
          styles={styles}
          isClearable={items.some(v => !v.isFixed)}
          name="managers"
          classNamePrefix="select"
          onChange={(value, actionMeta) => onChange(value, actionMeta, 'manager')}
          options={allUsers.filter(user => !items.find(i => i.value === user.value))}
          isDisabled={currentUser.role === 'viewer'}
        />
      </div>
      <div>
        <p className="mb-0">Viewers:</p>
        <Select
          className="mb-1"
          value={items.filter(i => i.role === 'viewer')}
          isMulti
          styles={styles}
          isClearable={items.some(v => !v.isFixed)}
          name="viewers"
          classNamePrefix="select"
          onChange={(value, actionMeta) => onChange(value, actionMeta, 'viewer')}
          options={allUsers.filter(user => !items.find(i => i.value === user.value))}
          isDisabled={currentUser.role === 'viewer'}
        />
      </div>
      <div className="d-flex justify-content-around">
        <Button color="danger" className="mx-2 mt-2" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="success" className="mx-2 mt-2" onClick={handleSave} disabled={!currentUser.role}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default UsersDropdown;
