import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import Button from '@components/ButtonLoader';
import { useHistory, Link, useParams } from 'react-router-dom';
import RankDistChart from './graphs/RankDistChart';
import T from '@json/en.json';
import PageImpactReportTable from './tables/PageImpactReportTable';
import DeleteConfirmationModal from '@components/DeleteConfirmationModal';
import { useOverviewQuery, useDeleteMutation } from '@components/urls/urlsApi';
import { showSuccess, showError } from '@components/Notifications';
import campaignsApi from '@components/campaigns/campaignsApi';
import { getFiltersFor, campaignBaseConfig } from '@utils';
import { useDispatch } from 'react-redux';
import { useDataConfig } from '@hooks/dataConfig';

const ViewUrl = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteMutation, deleteResult] = useDeleteMutation();
  const [filters, setFilters] = useState({});
  const { data: overview, isLoading } = useOverviewQuery({ id, filters });
  const { id: campaignId, name: campaignName } = overview?.campaign || {};
  const dataConfig = useDataConfig(campaignId, { skip: !campaignId });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (!campaignId) {
      return false;
    }

    dispatch(campaignsApi.endpoints.getConfig.initiate(campaignId)).then(result => {
      if (!result.isSuccess) {
        return false;
      }
      setFilters(getFiltersFor(null, { ...campaignBaseConfig, ...result.data }));
    });
  }, [campaignId, dispatch]);

  const handleDeleteURL = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async (bool_value, confirmValue) => {
    setIsDeleteModalOpen(bool_value);
    if (confirmValue === 'yes') {
      const { error } = await deleteMutation({ id });
      if (error) {
        showError('Not able to Delete URL');
        return;
      }
      showSuccess('URL Deleted Successfully');
      history.push(`/campaigns/${campaignId}/view`);
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <Spinner />
      </div>
    );
  }

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <Row>
            <Col xs="12" sm="8">
              <h4 className="">{T.CAMPAIGN.PAGE_IMPACT}</h4>
              <h3 className="mb-2">{campaignName}</h3>
              <h6 className="">{overview?.url}</h6>
            </Col>
            <Col xs="12" sm="4">
              <Link to={`/campaigns/${campaignId}/view`} className="btn btn-sm btn-danger back-button pull-right">
                {` << ${T.GENRIC.BACK}`}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {overview && (
        <Row>
          <ShowCard title="Links built" count={overview.links_built} countMM={overview.links_built_mm} />
          <ShowCard title="SOV*" count={`${overview.sov} %`} countMM={overview.sov_mm} />
          <ShowCard title="PAGE AUTHORITY" count={overview.page_authority} countMM={overview.page_authority_mm} />
          <ShowCard title="AVERAGE RANK" count={overview.keyword_rank} countMM={overview.keyword_rank_mm} />
        </Row>
      )}

      <Row>
        <Col>
          <Card className="mb-4">
            <CardBody>
              <Row>
                <div className="w-100">
                  <RankDistChart
                    showLeftAxis={false}
                    showRightAxis={false}
                    campaignId={campaignId}
                    dataConfig={dataConfig}
                    monthwiseDistribution={overview?.monthwise_distribution}
                  />
                </div>
              </Row>
              <Row>
                <Col>
                  <Card className="mb-4">
                    <CardBody className="p-0 pb-3">
                      <PageImpactReportTable reportUrlOverviewData={overview} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div>
                <Button onClick={handleDeleteURL} className="btn btn-danger delete-url" loading={deleteResult.isLoading}>
                  Delete URL
                </Button>
                {isDeleteModalOpen && (
                  <DeleteConfirmationModal
                    isDeleteModalOpen={isDeleteModalOpen}
                    handleDeleteConfirm={handleDeleteConfirm}
                    title={T.CAMPAIGN.CAMPAGIN_URL_DELETE_MESSAGE}
                    isCampaignUrlDeleteRequesting={deleteResult.isLoading}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewUrl;

const ShowCard = ({ title, count, countMM }) => {
  return (
    <div className="col-lg col-md-6 col-sm-6 mb-4">
      <div className="stats-small stats-small--1 card card-small">
        <div className="card-body p-0 d-flex">
          <div className="d-flex flex-column m-auto">
            <div className="stats-small__data text-center">
              <span className="stats-small__label text-uppercase">{title}</span>
              <h6 className="stats-small__value count my-3">{count}</h6>
              <p className="mb-0">
                <span className="mx-1">{Number(countMM).toFixed(2)}</span>
                <span className="mx-1">M/M</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
