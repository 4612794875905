import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@components/ButtonLoader';
import T from '@json/en.json';

const DeleteConfirmationModal = ({ isDeleteModalOpen, handleDeleteConfirm, title }) => {
  const toggle = value => {
    if (value === 'no') {
      return handleDeleteConfirm(false, 'no');
    }

    if (value === 'yes') {
      return handleDeleteConfirm(false, 'yes');
    }
  };

  return (
    <div>
      <Modal isOpen={isDeleteModalOpen} toggle={toggle}>
        <ModalHeader toggle={() => handleDeleteConfirm(false)} className="reason-modal-header">
          {T.GENRIC.CONFIRMATION}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">{title}</p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() => toggle('no')} className="cancel-button">
            {T.GENRIC.NO}
          </Button>{' '}
          <Button color="primary" onClick={() => toggle('yes')} className="modal-reject-button">
            {T.GENRIC.YES}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
