import { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Input, Form } from 'reactstrap';
import { showError, showSuccess } from '@components/Notifications';
import { useUpdateMutation, useGetByIdQuery } from '@components/campaigns/campaignsApi';
import T from '@json/en.json';
import { Label, Spinner } from 'reactstrap';
import { formatDate, parseDate } from '@utils';
import Button from '@components/ButtonLoader';
import DatePicker from 'react-datepicker';

const formInitialState = {
  campaign_name: '',
  report_graph_start: '',
  report_graph_end: '',
};

const requiredFields = ['campaign_name', 'report_graph_start', 'report_graph_end'];

const EditCampaign = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, isLoading } = useGetByIdQuery({ id });
  const [updateCampaign, updateCampaignResult] = useUpdateMutation();
  const [formData, setFormData] = useState(formInitialState);
  const [formDataError, setFormDataError] = useState({});

  useEffect(() => {
    if (!data) {
      return false;
    }
    setFormData(i => ({
      ...i,
      campaign_name: data.campaign.name,
      report_graph_start: parseDate(data.campaign.report_graph_start, 'YYYY-MM-DD'),
      report_graph_end: parseDate(data.campaign.report_graph_end, 'YYYY-MM-DD'),
    }));
  }, [data]);

  const handleFormChange = ({ target }) => {
    setFormData(i => ({ ...i, [target.name]: target.value }));
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    setFormDataError({});

    for (const field of requiredFields) {
      if (!formData[field]) {
        setFormDataError(i => ({ ...i, [field]: true }));
      }
    }

    if (!requiredFields.every(i => formData[i])) {
      return false;
    }

    const response = await updateCampaign({
      id: data.campaign.id,
      name: formData.campaign_name,
      report_graph_start: `${formatDate(formData.report_graph_start, 'YYYY-MM-15')}`,
      report_graph_end: `${formatDate(formData.report_graph_end, 'YYYY-MM-15')}`,
    });

    if (response.error) {
      return showError('Error updating Campaign!');
    }

    showSuccess(T.REPORT.UPDATE);

    return history.push('/campaigns');
  };

  const handleDateChange = (date, name) => {
    setFormData(i => ({ ...i, [name]: date }));
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <Spinner />
      </div>
    );
  }

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <h3 className="page-title">
            {T.CAMPAIGN.EDITCAMPAIGN}
            <Link to="/campaigns" className="btn btn-sm btn-danger back-button pull-right">
              {` << ${T.GENRIC.BACK}`}
            </Link>
          </h3>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Form id="add-campaign-form" onSubmit={onFormSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Label title="Required" className="fs mb-1">
                    {T.CAMPAIGN.FIELD_NAMES.CAMPAIGN_NAME}
                    {!formData.campaign_name && <span className="text-danger ms-1">*</span>}
                  </Label>
                  <Input
                    name="campaign_name"
                    disabled={false}
                    type="text"
                    placeholder={T.CAMPAIGN.FIELD_NAMES.CAMPAIGN_NAME}
                    className="form-control"
                    onChange={handleFormChange}
                    value={formData.campaign_name}
                  />
                  <div className="form-error mb-3 mt-0">{formDataError.campaign_name ? T.ERRORS.EMPTY : ''}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required" className="fs mb-1">
                    {T.CAMPAIGN.FIELD_NAMES.REPORT_START_DATE}
                    {!formData.report_graph_start && <span className="text-danger ms-1">*</span>}
                  </Label>
                  <DatePicker
                    showMonthYearPicker
                    selected={formData.report_graph_start}
                    name="report_graph_start"
                    className="form-control"
                    dateFormat="MMMM yyyy"
                    placeholderText="MM-YYYY"
                    onChange={date => handleDateChange(date, 'report_graph_start')}
                    maxDate={new Date()}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Label title="Required" className="fs mb-1">
                    {T.CAMPAIGN.FIELD_NAMES.REPORT_END_DATE}
                    {!formData.report_graph_end && <span className="text-danger ms-1">*</span>}
                  </Label>
                  <DatePicker
                    showMonthYearPicker
                    selected={formData.report_graph_end}
                    name="report_graph_end"
                    className="form-control"
                    dateFormat="MMMM yyyy"
                    placeholderText="MM-YYYY"
                    onChange={date => handleDateChange(date, 'report_graph_end')}
                    maxDate={new Date()}
                    minDate={formData.report_graph_start}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <Button disabled={!requiredFields.every(i => formData[i])} loading={updateCampaignResult.isLoading} className="btn btn-accent" type="submit">
              {T.GENRIC.SUBMIT}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default EditCampaign;
