import { Button, Spinner } from 'reactstrap';

const ButtonLoader = ({ children = null, loading = false, spinColor = '#fff', ...rest }) => {
  return (
    <Button {...rest} disabled={rest.disabled || loading}>
      <div className="d-flex justify-content-center align-items-center">
        {loading && <Spinner size="sm" style={{ color: spinColor, marginRight: '0.5rem' }} />}
        {children}
      </div>
    </Button>
  );
};

export default ButtonLoader;
