import { useEffect, useState, useRef } from 'react';
import { useGetKeywordsDistributionQuery } from '@components/campaigns/campaignsApi';
import RankDistChart from '@components/urls/graphs/RankDistChart';
import clsx from 'clsx';
import { getFiltersFor } from '@utils';
import GraphButtons from '@components/GraphButtons';
import { useDataConfig } from '@hooks/dataConfig';
import { Spinner } from 'reactstrap';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { MdDownload } from 'react-icons/md';

const KeywordDistributionGraph = ({ campaignId, segmentKey, title = '', width, onLoadEnd }) => {
  const [isExporting, setIsExporting] = useState(false);
  const exportRef = useRef();
  const dataConfig = useDataConfig(campaignId);
  const { data: { monthwise_distribution } = {}, isFetching, status } = useGetKeywordsDistributionQuery({ id: campaignId, filters: getFiltersFor(segmentKey, dataConfig) });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  const handleDownload = async () => {
    if (!exportRef || !exportRef.current) {
      return false;
    }

    setIsExporting(true);

    await new Promise(r => setTimeout(r, 1_000));

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = 0;
    }

    await exportComponentAsJPEG(exportRef, { fileName: `graph_export_${segmentKey}.jpg` });

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = null;
    }

    setIsExporting(false);
  };

  return (
    <div style={{ flexGrow: 1, maxWidth: width, width }} className="position-relative mx-auto" ref={exportRef}>
      <div className="no-export cursor-pointer absolute mb-4 my-2 opacity-60" style={{ left: 25, top: 5, position: 'absolute' }} onClick={handleDownload}>
        {!isExporting && <MdDownload className="fetch-text-size" />}
        {isExporting && <Spinner size="sm" className="mx-2 no-export" />}
      </div>
      {!isFetching && <GraphButtons className="no-export" sectionKey="keyword_distribution" segmentKey={segmentKey} dataConfig={dataConfig} campaignId={campaignId} />}
      <div className="d-flex align-items-center justify-content-center">
        <h5 className="text-center fs-6 my-2">{title || 'Rank Distribution'}</h5>
        {isFetching && <Spinner size="sm" className="mx-2 my-4" />}
      </div>
      <RankDistChart
        monthwiseDistribution={monthwise_distribution}
        width={width}
        className={clsx('mx-auto', isFetching && 'dim')}
        segmentKey={segmentKey}
        dataConfig={dataConfig}
        campaignId={campaignId}
      />
    </div>
  );
};

export default KeywordDistributionGraph;
