import { useMemo, useEffect, useRef, useState } from 'react';
import { Table, Spinner } from 'reactstrap';
import { useGetSOVQuery } from '@components/campaigns/campaignsApi';
import GraphButtons from '@components/GraphButtons';
import { colors, createYAxis, getClientControlYRange } from '@shared/graph';
import { getFiltersFor, percentageInAxis, formatPercentChange } from '@utils';
import { useDataConfig } from '@hooks/dataConfig';
import BasicBarChar from '@components/BasicBarChar';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { MdDownload } from 'react-icons/md';

const CampaignSOVChart = ({ campaignId, segmentKey, title = '', width, onLoadEnd, showTable = true }) => {
  const [isExporting, setIsExporting] = useState(false);
  const exportRef = useRef();
  const dataConfig = useDataConfig(campaignId);
  const { data, isFetching, status } = useGetSOVQuery({ id: campaignId, filters: getFiltersFor(segmentKey, dataConfig) });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  const chr = useMemo(() => {
    if (!data) {
      return {
        options: {
          yaxis: [
            { min: 0, max: 10, title: 'Campaign' },
            { min: 0, max: 10, title: 'Control', opposite: true },
          ],
          xaxis: { categories: [] },
        },
        series: [
          {
            name: 'Campaign',
            data: [],
            type: 'column',
          },
          {
            name: 'Control',
            data: [],
            type: 'spline',
          },
        ],
      };
    }

    const clientsList = data ? Object.values(data.sov_avg_monthwise.client) : [];
    const controlList = data ? Object.values(data.sov_avg_monthwise.control) : [];
    const monthKeys = data ? data.month_keys : [];

    const segmentZoom = (() => {
      if (!segmentKey || !dataConfig || !dataConfig.zoom || !dataConfig.zoom.share_of_voice) {
        return {};
      }

      return dataConfig.zoom.share_of_voice[segmentKey] || {};
    })();

    const clientZoom = segmentZoom.left;
    const controlZoom = segmentZoom.right;

    return getChr({ clientsList, controlList, monthKeys, clientZoom, controlZoom, segmentKey });
  }, [data, segmentKey, dataConfig]);

  const handleDownload = async () => {
    if (!exportRef || !exportRef.current) {
      return false;
    }

    setIsExporting(true);

    await new Promise(r => setTimeout(r, 1_000));

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = 0;
    }

    await exportComponentAsJPEG(exportRef, { fileName: `graph_export_${segmentKey}.jpg` });

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = null;
    }

    setIsExporting(false);
  };

  return (
    <div style={{ maxWidth: width, width }} className="position-relative mx-auto flex-grow-1 px-3" ref={exportRef}>
      <div className="no-export cursor-pointer absolute mb-4 my-2 opacity-60" style={{ left: 25, top: 5, position: 'absolute' }} onClick={handleDownload}>
        {!isExporting && <MdDownload className="fetch-text-size" />}
        {isExporting && <Spinner size="sm" className="mx-2 no-export" />}
      </div>
      {!isFetching && <GraphButtons className="no-export" sectionKey="share_of_voice" segmentKey={segmentKey} dataConfig={dataConfig} campaignId={campaignId} />}
      <div className="d-flex align-items-center justify-content-center">
        <h5 className="text-center fs-6 my-2">{title || 'Share of Voice'}</h5>
        {isFetching && <Spinner size="sm" className="mx-2 my-4" />}
      </div>
      <BasicBarChar
        isLoading={isFetching}
        chr={chr}
        sectionKey="share_of_voice"
        segmentKey={segmentKey}
        dataConfig={dataConfig}
        campaignId={campaignId}
        showLeftAxis={!isFetching}
        showRightAxis={!isFetching}
        leftRange={{ min: chr.options.yaxis[0].min, max: chr.options.yaxis[0].max }}
        rightRange={{ min: chr.options.yaxis[1].min, max: chr.options.yaxis[1].max }}
      />
      {showTable && data && data.month_keys && <ChartTable data={data} />}
    </div>
  );
};

const getChr = ({ clientsList, controlList, monthKeys, clientZoom, controlZoom, segmentKey }) => {
  return {
    options: {
      chart: {
        id: `sov|${segmentKey || '?'}`,
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: monthKeys,
      },
      yaxis: [
        createYAxis({
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          labels: {
            formatter: ({ value }) => percentageInAxis(value),
          },
          color: colors.black,
          allowDecimals: true,
          minTickInterval: 0.05,
          minRange: 0.05,
          startOnTick: false,
          tickInterval: 0.25,
          gridLineWidth: 1,
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList, zoom: clientZoom }).client,
          title: 'Campaign',
        }),
        createYAxis({
          opposite: true,
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          labels: {
            formatter: ({ value }) => percentageInAxis(value),
          },
          color: colors.black,
          allowDecimals: true,
          minTickInterval: 0.05,
          minRange: 0.05,
          startOnTick: false,
          tickInterval: 0.25,
          gridLineWidth: 0,
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList, zoom: controlZoom }).control,
          title: 'Control',
        }),
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: [0, 2],
        dashArray: 0,
      },
    },
    series: [
      {
        name: 'Campaign',
        data: clientsList,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Control',
        data: controlList,
        type: 'spline',
        color: '#00e396',
        yAxis: 1,
      },
    ],
    fill: {
      type: 'solid',
      colors: [colors.darkRed],
    },
  };
};

export default CampaignSOVChart;

const ChartTable = ({ data }) => {
  if (!data.metrics) {
    return null;
  }

  const { client, control, mm_diff, yy_diff, pp_diff } = data.metrics;
  const useClientYY = !!client.yy_average;
  const useControlYY = !!client.yy_average;

  return (
    <Table className="mb-4 border" size="sm" striped borderless={true} bordered={true}>
      <thead className="bg-light">
        <tr>
          <th></th>
          <th scope="col">M/M</th>
          <th scope="col">% Diff</th>
          <th scope="col">{useClientYY ? 'Y/Y' : 'P/P'}</th>
          <th scope="col">% Diff</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Campaign</strong>
          </td>
          <td>{formatPercentChange(client.mm_average)}</td>
          <td>{mm_diff}%</td>
          <td>{formatPercentChange(useClientYY ? client.yy_average : client.pp_average)}</td>
          <td>{`${useClientYY ? yy_diff : pp_diff}%`}</td>
        </tr>
        <tr>
          <td className="fix-rowspan-text">
            <strong>Control</strong>
          </td>
          <td>{formatPercentChange(control.mm_average)}</td>
          <td></td>
          <td>{formatPercentChange(useControlYY ? control.yy_average : control.pp_average)}</td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};
