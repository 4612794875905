import { Table } from 'reactstrap';

const PageImpactReportTable = ({ reportUrlOverviewData }) => {
  if (!reportUrlOverviewData) {
    return null;
  }

  return (
    <>
      <h4 className="keyword-rank-title">Keyword Rankings - Top 10</h4>
      <Table className="table mb-0">
        <thead className="bg-light">
          <tr>
            <th>Keyword</th>
            <th className="text-center">Searches</th>
            <th className="text-center">Position</th>
            <th className="text-center">SoV</th>
          </tr>
        </thead>
        <tbody>
          {reportUrlOverviewData.top_keywords?.map((i, index) => {
            return (
              <tr key={index}>
                <td>{i.keyword}</td>
                <td className="text-center">{i.search_volume}</td>
                <td className="text-center">{i.position}</td>
                <td className="text-center">{i.keyword_sov} %</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default PageImpactReportTable;
