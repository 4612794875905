import { useState, useEffect } from 'react';
import clsx from 'clsx';
import useBus from 'use-bus';
import Button from '@components/ButtonLoader';
import { Container, Row, Col, Card, CardHeader, CardBody, Input, Table, Spinner, Progress } from 'reactstrap';
import { Link } from 'react-router-dom';
import { formatDate } from '@utils';
import { useGetQuery, useUpdateMutation, useDeleteMutation, useFetchMutation } from '@components/linkcountdocuments/linkcountdocumentsApi';
import T from '@json/en.json';
import { showError, showSuccess } from '@components/Notifications';
import DeleteConfirmationModal from '@components/DeleteConfirmationModal';

const AllLinkcountDocument = () => {
  const { data = {}, isLoading, refetch } = useGetQuery();
  const [updateMutation] = useUpdateMutation();
  const [deleteMutation] = useDeleteMutation();
  const [fetchMutation] = useFetchMutation();
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const someRowIsBusy = data.results && data.results.some(i => i.status === 1);

  useBus(['task-started', 'task-failed', 'fetch-linkcountdocument-succeeded'], refetch, []);

  useEffect(() => {
    const interval = setInterval(refetch, 2000);

    if (!someRowIsBusy) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [someRowIsBusy, refetch]);

  const handleLinkDocumentChange = (e, item) => {
    if (e.target.checked) {
      setSelectedLinks(i => [...new Set([...i, item.id])]);
    } else {
      setSelectedLinks(items => items.filter(i => i !== item.id));
    }
  };

  const handleLinksUpdate = async ids => {
    const { error } = await fetchMutation(ids);

    if (error) {
      showError('Error fetching document data.');
    }

    setSelectedLinks([]);

    showSuccess('Document spreadsheet fetch job queued.');
  };

  const handleSelectAllLinkUpdate = e => {
    if (!e.target.checked) {
      return setSelectedLinks([]);
    }

    return setSelectedLinks(data.results ? data.results.map(i => i.id) : []);
  };

  const handleDeleteConfirm = async (value, confirmValue) => {
    setIsDeleteModalOpen(value);
    if (confirmValue === 'yes') {
      const { error } = await deleteMutation({ id: selectedId });

      if (error) {
        return showError("Can't delete Document");
      }

      showSuccess('Document deleted');
    }
  };

  const handleLinkDelete = value => {
    setSelectedId(value.id);
    setIsDeleteModalOpen(true);
  };

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <span className="text-uppercase page-subtitle">{T.OVERVIEW.TITILE}</span>
          <h3 className="page-title">
            <span className="mr-3">{T.LINKCOUNTDOCUMENT.ALLLINKCOUNTDOCUMENT}</span>
            <Button className="btn btn-sm btn-primary add-button mx-0 my-3 pull-right" disabled={selectedLinks.length === 0} onClick={() => handleLinksUpdate(selectedLinks)}>
              {T.GENRIC.UPDATESELECTED}
            </Button>
            <Link to="/linkcountdocuments/add" className="btn btn-sm btn-primary add-button mx-0 my-3">
              {T.LINKCOUNTDOCUMENT.ADDLINKCOUNTDOCUMENT}
            </Link>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
          {!isLoading && (
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{T.LINKCOUNTDOCUMENT.ALLLINKCOUNTDOCUMENT}</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <Table responsive>
                  <thead className="bg-light">
                    <tr>
                      <th scope="col">
                        <Input
                          type="checkbox"
                          className="custom-control-input m-1"
                          checked={data.results && data.results.length === selectedLinks.length && data.results.length !== 0}
                          onChange={handleSelectAllLinkUpdate}
                        />
                      </th>
                      <th scope="col">{T.LINKCOUNTDOCUMENT.FIELD_NAMES.NAME}</th>
                      <th scope="col" className="text-center">
                        {T.LINKCOUNTDOCUMENT.FIELD_NAMES.STATUS}
                      </th>
                      <th scope="col">{T.LINKCOUNTDOCUMENT.FIELD_NAMES.LAST_UPDATED}</th>
                      <th scope="col">{T.LINKCOUNTDOCUMENT.FIELD_NAMES.ACTIONS}</th>
                      <th scope="col">{T.LINKCOUNTDOCUMENT.FIELD_NAMES.INACTIVE}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.results &&
                      data.results.map(i => {
                        const inProgress = i.status === 1;
                        const isFailure = i.status === 2;
                        const isReady = i.status === 0;

                        const buttonText = (() => {
                          if (isFailure) {
                            return 'Failure';
                          }

                          if (inProgress && i.busy_progress === 0) {
                            return 'Queued';
                          }

                          if (inProgress) {
                            return 'Loading...';
                          }

                          if (isReady) {
                            return 'Ready';
                          }

                          return 'New';
                        })();

                        const buttonColor = (() => {
                          if (isFailure) {
                            return 'danger';
                          }

                          if (inProgress) {
                            return 'warning';
                          }

                          if (isReady) {
                            return 'success';
                          }

                          return 'secondary';
                        })();

                        return (
                          <tr key={i.id}>
                            <td>
                              <Input type="checkbox" className="custom-control-input m-1" onChange={e => handleLinkDocumentChange(e, i)} checked={selectedLinks.includes(i.id)} />
                            </td>
                            <td>{i.name}</td>
                            <td>
                              <Progress
                                value={i.status === 1 ? i.busy_progress * 100 : 100}
                                animated={false}
                                color={buttonColor}
                                barStyle={{ height: 24, width: 120, overflow: 'visible' }}
                                style={{ height: 24, width: 120, margin: '0 auto' }}
                              >
                                <div className={clsx('absolute', inProgress ? 'text-black' : 'text-white')} style={{ width: 120, fontSize: '0.75rem' }}>
                                  {buttonText}
                                </div>
                              </Progress>
                            </td>
                            <td>{formatDate(i.updated_date, 'MM/DD/YYYY')}</td>
                            <td className={clsx(inProgress && 'opacity-40')}>
                              <i title={T.GENRIC.UPDATE} className="material-icons mr-3 row-icons" onClick={() => !inProgress && handleLinksUpdate([i.id])}>
                                refresh
                              </i>
                              <i title={T.GENRIC.DELETE} className="material-icons mr-3 row-icons" onClick={() => !inProgress && handleLinkDelete(i)}>
                                delete
                              </i>
                            </td>
                            <td>
                              <div className="text-center me-5">
                                <input
                                  title={T.LINKCOUNTDOCUMENT.FIELD_NAMES.INACTIVE}
                                  type="checkbox"
                                  checked={!i.is_active}
                                  onChange={e => updateMutation({ ...i, is_active: !e.target.checked })}
                                  disabled={inProgress}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {data.results && data.results.length === 0 && <div className="no-data">{T.GENRIC.NO_RECORDS_FOUND}</div>}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
      {isDeleteModalOpen && (
        <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} handleDeleteConfirm={handleDeleteConfirm} title={T.LINKCOUNTDOCUMENT.LINKCOUNTDOCUMENT_DELETE_MESSAGE} />
      )}
    </Container>
  );
};

export default AllLinkcountDocument;
