import T from '@json/en.json';
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';

const user = JSON.parse(cookies.get('user') || '{}');
const CampaignButtons = ({ campaign, inProgress, onDelete }) => {
  const canDelete = campaign.users
    .filter(e => ['owner', 'creator'].includes(e.role))
    .map(e => e.id)
    .includes(user.id);
  if ([2, 3].includes(campaign.status)) {
    return (
      <div className="d-flex justify-content-around align-items-center">
        <Link to={`/campaigns/${campaign.id}/reports`}>
          <i title={T.REPORT.REPORTS} className="material-icons m-2 row-icons text-black">
            note_add
          </i>
        </Link>
        <Link to={`/campaigns/${campaign.id}/view`}>
          <i title={T.CAMPAIGN.VIEWCAMPAIGN} className="material-icons m-2 row-icons text-black">
            visibility
          </i>
        </Link>
        <Link to={`/campaigns/${campaign.id}/edit`}>
          <i title={T.CAMPAIGN.EDITCAMPAIGN} className="material-icons m-2 row-icons  text-black">
            edit
          </i>
        </Link>
        {canDelete ? (
          <i title={T.CAMPAIGN.DELETECAMPAIGN} className="material-icons m-2 row-icons" onClick={() => onDelete && onDelete(campaign)}>
            delete
          </i>
        ) : (
          <i title={T.CAMPAIGN.UNAVAILABLE_WORKING} className="opacity-10 material-icons m-2 row-icons">
            delete
          </i>
        )}
      </div>
    );
  }

  if (inProgress) {
    return (
      <div className="d-flex justify-content-around align-items-center">
        <i title={T.CAMPAIGN.UNAVAILABLE_WORKING} className="opacity-10 material-icons m-2 row-icons text-black">
          note_add
        </i>
        <i title={T.CAMPAIGN.UNAVAILABLE_WORKING} className="opacity-10 material-icons m-2 row-icons text-black">
          visibility
        </i>
        <i title={T.CAMPAIGN.UNAVAILABLE_WORKING} className="opacity-10 material-icons m-2 row-icons  text-black">
          edit
        </i>
        <i title={T.CAMPAIGN.UNAVAILABLE_WORKING} className="opacity-10 material-icons m-2 row-icons">
          delete
        </i>
      </div>
    );
  }

  return <div />;
};

export default CampaignButtons;
