import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';

const urlsApi = createApi({
  reducerPath: 'urlsApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  tagTypes: ['urls'],
  endpoints: builder => ({
    overview: builder.query({
      query: arg => {
        const { id, filters = {} } = arg;
        const params = {};

        if (filters.top) {
          params.top = filters.top;
        }
        if (filters.position) {
          params.position = filters.position;
        }
        if (filters.priority && filters.priority.length) {
          params.priority = filters.priority;
        }
        if (filters.exclude_exact && filters.exclude_exact.length) {
          params.exclude_exact = filters.exclude_exact;
        }
        if (filters.exclude_containing && filters.exclude_containing.length) {
          params.exclude_containing = filters.exclude_containing;
        }

        return {
          url: `urls/${id}/overview`,
          params,
        };
      },
    }),
    delete: builder.mutation({
      query: url => ({ url: `urls/${url.id}`, method: 'DELETE' }),
    }),
    update: builder.mutation({
      query: url => ({ url: `urls/${url.id}`, method: 'PATCH', body: url }),
    }),
  }),
});

export const { useOverviewQuery, useDeleteMutation, useUpdateMutation } = urlsApi;

export default urlsApi;
