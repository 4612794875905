import { useEffect, useState, useMemo, useCallback } from 'react';
import useBus from 'use-bus';
import { ucFirst, formatDate, getLastMonth } from '@utils';
import { Container, Row, Col, Card, CardHeader, CardBody, Input, Spinner, Table } from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import { useGetQuery, useUpdateMutation, useDeleteMutation, useFetchMutation } from '@components/campaigns/campaignsApi';
import clsx from 'clsx';
import T from '@json/en.json';
import { showSuccess, showError } from '@components/Notifications';
import UsersDropdown from '@components/UsersDropdown';
import DeleteConfirmationModal from '@components/DeleteConfirmationModal';
import StartEndMonthYearPicker from '@components/StartEndMonthYearPicker';
import CampaignButtons from './CampaignButtons';
import CampaignStatus from './CampaignStatus';
import CampaignCheckbox from './CampaignCheckbox';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useDispatch, useSelector } from 'react-redux';
import DropdownHideableColumns from './DropdownHideableColumns';
import { toggleHideInactive } from '@redux/appSlice';

const AllCampaign = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { columns, hideInactive } = useSelector(state => state.app.settings.allCampaigns);
  const { data = {}, isLoading, refetch: refetchCampaigns } = useGetQuery({ key: location.key, hideInactive });
  const [updateMutation, updateResult] = useUpdateMutation();
  const [deleteMutation] = useDeleteMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [editingUsersIndex, setEditingUsersIndex] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [fetchStart, setFetchStart] = useState(null);
  const [fetchEnd, setFetchEnd] = useState(getLastMonth());
  const [isFetching, setIsFetching] = useState(false);
  const [fetchCampaign] = useFetchMutation();
  const getQueryData = useMemo(() => {
    if (!data.results) {
      return [];
    }
    return data.results;
  }, [data.results]);

  const someRowIsBusy = getQueryData.some(i => i.status === 1);
  const readyAndActiveCampaigns = getQueryData.filter(c => c.is_active && c.status === 2);

  useEffect(() => {
    const interval = setInterval(refetchCampaigns, 6000);

    if (!someRowIsBusy) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [someRowIsBusy, refetchCampaigns]);

  useBus(
    ['setup-campaign-succeeded', 'report-campaign-succeeded', 'task-failed', 'task-started'],
    ({ type, payload }) => {
      refetchCampaigns();
      if (type === 'report-campaign-succeeded' && getQueryData.length) {
        const campaign = getQueryData.find(i => i.id === payload.campaign_id);
        if (campaign) {
          showSuccess(`Report for Campaign ${campaign.name} ready.`);
        }
      }
    },
    []
  );

  const handleCampaignSelectChange = (e, item) => {
    if (e.target.checked) {
      setSelectedCampaigns(i => [...new Set([...i, item])]);
    } else {
      setSelectedCampaigns(items => items.filter(i => i.id !== item.id));
    }
  };

  const handleDeleteConfirm = (bool_value, confirmValue) => {
    setIsDeleteModalOpen(bool_value);
    if (confirmValue === 'yes') {
      deleteMutation({ id: selectedId });
    }
  };

  const handleDelete = value => {
    setSelectedId(value.id);
    setIsDeleteModalOpen(true);
  };

  const getCampaignArray = () => {
    if (selectedCampaigns.length !== 0) {
      return selectedCampaigns;
    } else {
      return getQueryData;
    }
  };

  const handleFetch = async (startDate, endDate) => {
    setIsFetching(true);

    let campaigns = getCampaignArray();

    for (const campaign of campaigns) {
      if (!campaign.is_active) {
        continue;
      }

      const { error } = await fetchCampaign({
        campaign,
        data: { start_date: formatDate(startDate, 'YYYY-MM-15'), end_date: formatDate(endDate, 'YYYY-MM-15') },
      });

      if (error) {
        showError(`Error when fetching Campaign "${campaign.name}"`);
      }
    }
    setIsFetching(false);
    setSelectedCampaigns([]);
  };

  const handleUsersSave = async (users, campaign) => {
    const { data } = await updateMutation({ ...campaign, users });

    if (data && data.name) {
      showSuccess(`Users for campaign ${data.name} updated.`);
    }

    setEditingUsersIndex(null);
  };

  const getFlagIcon = (country = '') => {
    if (!country) {
      return null;
    }

    if (country.toLowerCase() === 'uk') {
      return getUnicodeFlagIcon('gb');
    }

    if (!hasFlag(country.toUpperCase())) {
      return null;
    }

    return getUnicodeFlagIcon(country);
  };

  const isColumnVisible = useCallback(columnName => columns[columnName] && columns[columnName].visible, [columns]);

  return (
    <Container fluid className="main-content-container container-fluid px-4">
      <Row className="page-header py-4">
        <Col className="text-sm-left">
          <span className="text-uppercase page-subtitle">{T.OVERVIEW.TITILE}</span>
          <h3 className="page-title">
            <span className="mr-3">{T.CAMPAIGN.ALLCAMPAIGN}</span>
            <Link to="/campaigns/add" className="btn btn-sm btn-primary add-button mx-0 my-3">
              {T.CAMPAIGN.ADDCAMPAIGN}
            </Link>
          </h3>
        </Col>
        <Col className="my-auto mr-auto ml-0">
          <StartEndMonthYearPicker
            startDate={fetchStart}
            endDate={fetchEnd}
            startDateHandler={setFetchStart}
            endDateHandler={setFetchEnd}
            onButtonClick={handleFetch}
            loading={isFetching}
            submitText={selectedCampaigns.length === 0 ? 'Fetch' : 'Fetch Selected'}
            labelStartText="Fetch Start Date"
            labelEndText="Fetch End Date"
            buttonTitle={selectedCampaigns.length === 0 ? 'Fetch data for all active campaigns' : 'Fetch data for the campaigns you selected'}
            buttonDisabled={!fetchEnd || isFetching}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
          {!isLoading && (
            <Card className="mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <CardHeader className="border-bottom" style={{ width: '80%' }}>
                  <h6 className="m-0">{T.CAMPAIGN.ALLCAMPAIGN}</h6>
                </CardHeader>
                <div className={'border-bottom d-flex align-items-center justify-content-end'} style={{ width: '35%', height: 60 }}>
                  <div className="px-3 py-2 d-flex bg-gray rounded mx-2 cursor-pointer" onClick={() => dispatch(toggleHideInactive())}>
                    <input type="checkbox" readOnly checked={hideInactive} />
                    <p className="ml-2 my-0 fw-bold fs-8 text-center">Hide Inactive</p>
                  </div>
                  <DropdownHideableColumns />
                </div>
              </div>
              <CardBody className="p-0 pb-3">
                {getQueryData.length === 0 && <div className="text-center pt-3">{T.GENRIC.NO_RECORDS_FOUND}</div>}
                {getQueryData.length > 0 && (
                  <Table>
                    <thead className="bg-light text-center">
                      <tr>
                        <th scope="col">
                          <Input
                            type="checkbox"
                            className="custom-control-input m-1"
                            checked={selectedCampaigns.length >= readyAndActiveCampaigns.length && readyAndActiveCampaigns.length > 0}
                            onChange={e => setSelectedCampaigns(e.target.checked ? readyAndActiveCampaigns : [])}
                            disabled={getQueryData.length === 0}
                          />
                        </th>
                        {isColumnVisible('country_flag') && <th scope="col"></th>}
                        {isColumnVisible('name') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.NAME}</th>}
                        {isColumnVisible('data_date_range') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.DATA_DATE_RANGE}</th>}
                        {isColumnVisible('report_date_range') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.REPORT_DATE_RANGE}</th>}
                        {isColumnVisible('last_updated') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.LAST_UPDATED}</th>}
                        {isColumnVisible('status') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.STATUS}</th>}
                        {isColumnVisible('users') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.USERS}</th>}
                        <th scope="col">{T.CAMPAIGN.FIELD_NAMES.ACTIONS}</th>
                        {isColumnVisible('inactive') && <th scope="col">{T.CAMPAIGN.FIELD_NAMES.INACTIVE}</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {getQueryData.map((i, index) => {
                        const inProgress = i.status === 1;
                        const isUpdating = updateResult.originalArgs && updateResult.originalArgs.id === i.id && updateMutation.isLoading;

                        return (
                          <tr
                            key={i.id}
                            className={clsx(highlightedIndex === index && 'highlighted-row')}
                            onMouseLeave={() => setHighlightedIndex(null)}
                            onMouseEnter={() => setHighlightedIndex(index)}
                          >
                            <td>
                              <Input
                                type="checkbox"
                                className="custom-control-input m-1"
                                onChange={e => handleCampaignSelectChange(e, i)}
                                checked={selectedCampaigns.some(c => c.id == i.id)}
                                disabled={Boolean(!i.is_active || isUpdating || i.status !== 2)}
                              />
                            </td>
                            {isColumnVisible('country_flag') && (
                              <td className="text-center">
                                {getFlagIcon(i.country)} {(i.country || '').toUpperCase()}
                              </td>
                            )}
                            {isColumnVisible('name') && (
                              <td className="text-center">
                                <Link to={`/campaigns/${i.id}/view`}>{i.name}</Link>
                              </td>
                            )}
                            {isColumnVisible('data_date_range') && (
                              <td className="text-center">{`${formatDate(i.data_date_range?.start_date)} to ${formatDate(i.data_date_range?.end_date)}`}</td>
                            )}
                            {isColumnVisible('report_date_range') && <td className="text-center">{`${formatDate(i.report_graph_start)} to ${formatDate(i.report_graph_end)}`}</td>}
                            {isColumnVisible('last_updated') && <td className="text-center">{formatDate(i.updated_date)}</td>}
                            {isColumnVisible('status') && (
                              <td>
                                <CampaignStatus status={i.status} busyProgress={i.busy_progress} />
                              </td>
                            )}
                            {isColumnVisible('users') && (
                              <td>
                                {editingUsersIndex === index ? (
                                  <UsersDropdown users={i.users} onCancel={() => setEditingUsersIndex(null)} onSave={users => handleUsersSave(users, i)} />
                                ) : (
                                  <div onClick={() => setEditingUsersIndex(index)} className="cursor-pointer">
                                    {i.users.length === 0 && <div className="text-center">?</div>}
                                    <div className="text-center fw-bolder">
                                      {i.users
                                        .filter(i => ['owner', 'creator'].includes(i.role))
                                        .map(i => ucFirst(i.username))
                                        .join(' | ')}
                                    </div>
                                    <div className="text-center">
                                      {i.users
                                        .filter(i => i.role === 'manager')
                                        .map(i => ucFirst(i.username))
                                        .join(' | ')}
                                    </div>
                                    <div className="text-center">
                                      {i.users
                                        .filter(i => i.role === 'viewer')
                                        .map(i => ucFirst(i.username))
                                        .join(' | ')}
                                    </div>
                                  </div>
                                )}
                              </td>
                            )}
                            <td>
                              <CampaignButtons campaign={i} onDelete={handleDelete} inProgress={inProgress} />
                            </td>
                            {isColumnVisible('inactive') && (
                              <td>
                                <CampaignCheckbox setSelectedCampaigns={setSelectedCampaigns} isUpdating={isUpdating} campaign={i} updateMutation={updateMutation} />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
      {isDeleteModalOpen && <DeleteConfirmationModal isDeleteModalOpen={isDeleteModalOpen} handleDeleteConfirm={handleDeleteConfirm} title={T.CAMPAIGN.CAMPAGIN_DELETE_MESSAGE} />}
    </Container>
  );
};

export default AllCampaign;
