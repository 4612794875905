import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE_URL as baseUrl, API_FLOWER_BASE_URL as flowerBaseUrl } from '@redux/apiEndPoint';
import { prepareHeaders } from '@redux/utils';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { diff } from 'deep-object-diff';
import { setUser } from '@redux/appSlice';
import { signIn, base62Decode } from '@utils';

const campaignsApi = createApi({
  reducerPath: 'campaignsApi',
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
  tagTypes: ['Campaigns', 'Reports', 'SOV', 'Links', 'KeywordsRanking', 'KeywordsDitribution', 'Authority'],
  endpoints: builder => ({
    add: builder.mutation({
      query: campaign => ({ url: `campaigns/`, method: 'POST', body: campaign }),
      invalidatesTags: ({ id }) => {
        return [{ type: 'Campaigns', id }];
      },
    }),
    addReport: builder.mutation({
      query: ({ campaign, data }) => ({ url: `campaigns/${campaign.id}/reports`, method: 'POST', body: data }),
    }),
    get: builder.query({
      query: ({ hideInactive = false }) => {
        const params = {};

        if (hideInactive) {
          params.is_active = true;
        }

        return { url: 'campaigns/', params };
      },
      providesTags: response => {
        if (!response) {
          return [{ type: 'Campaigns', id: 'LIST' }];
        }
        return [...response.results.map(i => ({ type: 'Campaigns', id: i.id })), { type: 'Campaigns', id: 'LIST' }];
      },
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.get');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getById: builder.query({
      query: ({ id, params }) => {
        return {
          url: `campaigns/${id}`,
          params,
        };
      },
      providesTags: (results, error, id) => [{ type: 'Campaigns', id }],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getById');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getSOV: builder.query({
      query: ({ id, filters = {} }) => {
        const params = {};

        if (filters.top) {
          params.top = filters.top;
        }
        if (filters.position) {
          params.position = filters.position;
        }
        if (filters.priority && filters.priority.length) {
          params.priority = filters.priority;
        }
        if (filters.exclude_exact && filters.exclude_exact.length) {
          params.exclude_exact = filters.exclude_exact;
        }
        if (filters.exclude_containing && filters.exclude_containing.length) {
          params.exclude_containing = filters.exclude_containing;
        }

        return {
          url: `campaigns/${id}/sov`,
          params,
        };
      },
      providesTags: ['SOV'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getSOV');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getLinks: builder.query({
      query: id => `campaigns/${id}/links`,
      providesTags: ['Links'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getLinks');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getConfig: builder.query({
      query: id => `campaigns/${id}/config`,
      providesTags: ['Config'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getConfig');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getAuthority: builder.query({
      query: id => `campaigns/${id}/authority`,
      providesTags: ['Authority'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getAuthority');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getKeywordsRanking: builder.query({
      query: arg => {
        const { id, filters = {} } = arg;
        const params = {};

        if (filters.top) {
          params.top = filters.top;
        }
        if (filters.position) {
          params.position = filters.position;
        }
        if (filters.priority && filters.priority.length) {
          params.priority = filters.priority;
        }
        if (filters.exclude_exact && filters.exclude_exact.length) {
          params.exclude_exact = filters.exclude_exact;
        }
        if (filters.exclude_containing && filters.exclude_containing.length) {
          params.exclude_containing = filters.exclude_containing;
        }

        return {
          url: `campaigns/${id}/keywords/ranking`,
          params,
        };
      },
      providesTags: ['KeywordsRanking'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getKeywordsRanking');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getKeywordsDistribution: builder.query({
      query: arg => {
        const { id, filters = {} } = arg;
        const params = {};

        if (filters.top) {
          params.top = filters.top;
        }
        if (filters.position) {
          params.position = filters.position;
        }
        if (filters.priority && filters.priority.length) {
          params.priority = filters.priority;
        }
        if (filters.exclude_exact && filters.exclude_exact.length) {
          params.exclude_exact = filters.exclude_exact;
        }
        if (filters.exclude_containing && filters.exclude_containing.length) {
          params.exclude_containing = filters.exclude_containing;
        }

        return {
          url: `campaigns/${id}/keywords/distribution`,
          params,
        };
      },
      providesTags: ['KeywordsDitribution'],
      async onQueryStarted(_, { queryFulfilled }) {
        const bugsnagSpan = BugsnagPerformance.startSpan('campaignsApi.getKeywordsDistribution');
        await queryFulfilled;
        bugsnagSpan.end();
      },
    }),
    getCsvExport: builder.query({
      query: arg => {
        const { id, filters = {} } = arg;
        const params = {};

        if (filters.priority && filters.priority.length) {
          params.priority = filters.priority;
        }
        if (filters.exclude_exact && filters.exclude_exact.length) {
          params.exclude_exact = filters.exclude_exact;
        }
        if (filters.exclude_containing && filters.exclude_containing.length) {
          params.exclude_containing = filters.exclude_containing;
        }

        return {
          url: `campaigns/${id}/csv_export`,
          params,
        };
      },
    }),
    getTasks: builder.query({
      query: () => `${flowerBaseUrl}tasks`,
    }),
    update: builder.mutation({
      query: campaign => ({ url: `campaigns/${campaign.id}`, method: 'PATCH', body: campaign }),
      invalidatesTags: (results, error, args) => {
        if (Object.keys(args).join() === ['id', 'graph_data'].join()) {
          return ['Campaigns'];
        }
        return ['Campaigns', 'SOV', 'Links', 'KeywordsRanking', 'KeywordsDitribution', 'Authority'];
      },
    }),

    updateConfig: builder.mutation({
      query: ({ campaign, config }) => ({ url: `campaigns/${campaign.id}/config`, method: 'PATCH', body: config }),
      async onQueryStarted({ campaign }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            campaignsApi.util.updateQueryData('getConfig', campaign.id, oldConfig => {
              const patchedKeys = Object.keys(diff(oldConfig, data));

              if (patchedKeys.length === 1 && ['zoom', 'pdf_export'].includes(patchedKeys[0])) {
                return { ...oldConfig, ...data };
              }

              dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaigns', id: campaign.id }]));

              return { ...oldConfig, ...data };
            })
          );
        } catch (e) {
          dispatch(campaignsApi.util.invalidateTags(['getConfig']));
        }
      },
    }),

    delete: builder.mutation({
      query: campaign => ({ url: `campaigns/${campaign.id}`, method: 'DELETE' }),
      invalidatesTags: (results, error, { id }) => {
        return [{ type: 'Campaigns', id }];
      },
    }),
    fetch: builder.mutation({
      query: ({ campaign, data }) => ({ url: `campaigns/${campaign.id}/fetch`, method: 'POST', body: data }),
    }),
    addUrl: builder.mutation({
      query: campaign => ({ url: `campaigns/${campaign.id}/url`, method: 'POST', body: campaign }),
      invalidatesTags: ['Campaigns', 'SOV', 'Links', 'KeywordsRanking', 'KeywordsDitribution', 'Authority'],
    }),
    getLinkLaunchURLs: builder.query({
      query: args => {
        if (args && args.auth) {
          const decodedArgs = JSON.parse(base62Decode(args.auth));

          return {
            url: `campaigns/link-launch-urls`,
            headers: { Authorization: `Bearer ${decodedArgs.auth.token}` },
          };
        }

        return `campaigns/link-launch-urls`;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        if (!args || !args.auth) {
          return null;
        }
        await queryFulfilled;

        const decodedArgs = JSON.parse(base62Decode(args.auth));
        const user = { ...decodedArgs.user, auth: decodedArgs.auth };
        signIn(user);
        dispatch(setUser(user));
      },
    }),
  }),
});

export const {
  useAddMutation,
  useAddReportMutation,
  useDeleteMutation,
  useFetchMutation,
  useUpdateConfigMutation,
  useGetAuthorityQuery,
  useGetByIdQuery,
  useGetKeywordsDistributionQuery,
  useGetKeywordsRankingQuery,
  useGetLinksQuery,
  useGetConfigQuery,
  useGetQuery,
  useGetSOVQuery,
  useGetTasksQuery,
  useUpdateMutation,
  useAddUrlMutation,
  useGetLinkLaunchURLsQuery,
  useLazyGetLinkLaunchURLsQuery,
} = campaignsApi;

export default campaignsApi;
