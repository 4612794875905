import { useMemo, useEffect, useRef, useState } from 'react';
import { Table, Spinner } from 'reactstrap';
import { useGetKeywordsRankingQuery } from '@components/campaigns/campaignsApi';
import { colors, createYAxis, getClientControlYRange } from '@shared/graph';
import { getFiltersFor, formatPercentChange } from '@utils';
import GraphButtons from '@components/GraphButtons';
import { useDataConfig } from '@hooks/dataConfig';
import BasicBarChar from '@components/BasicBarChar';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { MdDownload } from 'react-icons/md';

const CampaignKeywordRankingChart = ({ campaignId, segmentKey, title = '', width, showTable = true, onLoadEnd }) => {
  const [isExporting, setIsExporting] = useState(false);
  const exportRef = useRef();
  const dataConfig = useDataConfig(campaignId);
  const { data: keywordRanks, isFetching, status } = useGetKeywordsRankingQuery({ id: campaignId, filters: getFiltersFor(segmentKey, dataConfig) });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  const { clientsList, controlList } = useMemo(() => {
    const { rank_avg_monthwise, month_keys = [] } = keywordRanks || {};
    const initData = { clientsList: [], controlList: [] };

    return (
      month_keys.reduce((acc, month) => {
        acc.clientsList.push(rank_avg_monthwise?.['client']?.[month] || 0);
        acc.controlList.push(rank_avg_monthwise?.['control']?.[month] || 0);
        return acc;
      }, initData) ?? {}
    );
  }, [keywordRanks]);

  const segmentZoom = useMemo(() => {
    if (!(segmentKey && dataConfig && dataConfig.zoom && dataConfig.zoom.keyword_rankings)) {
      return {};
    }

    return dataConfig.zoom.keyword_rankings[segmentKey] || {};
  }, [dataConfig, segmentKey]);

  const chr = {
    options: {
      chart: {
        id: `keyword-rankings|${segmentKey || '?'}`,
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: keywordRanks?.month_keys || [],
      },
      yaxis: [
        createYAxis({
          reversed: true,
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          color: colors.black,
          title: 'Campaign',
          axisBorderOffsetX: -5,
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList, zoom: segmentZoom.left }).client,
        }),
        createYAxis({
          reversed: true,
          opposite: true,
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          color: colors.black,
          title: 'Control',
          axisBorderOffsetX: -5,
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList, zoom: segmentZoom.right }).control,
        }),
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: [2, 2],
        dashArray: 0,
      },
    },
    series: [
      {
        name: 'Campaign',
        data: clientsList,
        type: 'spline',
        color: '#008ffb',
        yAxis: 0,
      },
      {
        name: 'Control',
        data: controlList,
        type: 'spline',
        color: '#00e396',
        yAxis: 1,
      },
    ],
    fill: {
      type: 'solid',
      colors: [colors.darkRed],
    },
  };

  const handleDownload = async () => {
    if (!exportRef || !exportRef.current) {
      return false;
    }

    setIsExporting(true);

    await new Promise(r => setTimeout(r, 1_000));

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = 0;
    }

    await exportComponentAsJPEG(exportRef, { fileName: `graph_export_${segmentKey}.jpg` });

    for (const i of exportRef.current.getElementsByClassName('no-export')) {
      i.style.opacity = null;
    }

    setIsExporting(false);
  };

  return (
    <div style={{ flexGrow: 1, maxWidth: width, width }} className="position-relative mx-auto" ref={exportRef}>
      <div className="no-export cursor-pointer absolute mb-4 my-2 opacity-60" style={{ left: 25, top: 5, position: 'absolute' }} onClick={handleDownload}>
        {!isExporting && <MdDownload className="fetch-text-size" />}
        {isExporting && <Spinner size="sm" className="mx-2 no-export" />}
      </div>
      {!isFetching && <GraphButtons className="no-export" sectionKey="keyword_rankings" segmentKey={segmentKey} dataConfig={dataConfig} campaignId={campaignId} />}
      <div className="d-flex align-items-center justify-content-center">
        <h5 className="text-center fs-6 my-2">{title || 'Average Keyword Rankings'}</h5>
        {isFetching && <Spinner size="sm" className="mx-2 my-4" />}
      </div>
      <BasicBarChar
        chr={chr}
        sectionKey="keyword_rankings"
        segmentKey={segmentKey}
        campaignId={campaignId}
        dataConfig={dataConfig}
        showLeftAxis={!isFetching}
        showRightAxis={!isFetching}
        leftRange={{ min: chr.options.yaxis[0].min, max: chr.options.yaxis[0].max }}
        rightRange={{ min: chr.options.yaxis[1].min, max: chr.options.yaxis[1].max }}
      />
      {showTable && <ChartTable keywordRanks={keywordRanks} isFetching={isFetching} />}
    </div>
  );
};

const ChartTable = ({ keywordRanks, isFetching }) => {
  const { metrics = {}, month_keys } = keywordRanks || {};

  if (isFetching) {
    return null;
  }

  return <DiffTable metrics={metrics} month_keys={month_keys} />;
};

const DiffTable = ({ metrics: { client, control, yy_diff, mm_diff, pp_diff }, month_keys }) => {
  if (!client || !control || !month_keys || !month_keys.length) {
    return null;
  }

  const showY = Boolean(client.yy_average || control.yy_average);
  const clientAvgPpYy = client[showY ? 'yy_average' : 'pp_average'];
  const controlAvgPpYy = control[showY ? 'yy_average' : 'pp_average'];

  return (
    <Table className="mb-4 border" size="sm" striped borderless={true} bordered={true}>
      <thead>
        <tr>
          <th></th>
          <th>M/M</th>
          <th>% Diff</th>
          <th>{showY ? 'Y/Y' : 'P/P'}</th>
          <th>% Diff</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Campaign</td>
          <td>{formatPercentChange(client.mm_average)}</td>
          <td>{mm_diff}%</td>
          <td>{formatPercentChange(clientAvgPpYy)}</td>
          <td>{showY ? yy_diff : pp_diff}%</td>
        </tr>
        <tr>
          <td>Control</td>
          <td>{formatPercentChange(control.mm_average)}</td>
          <td></td>
          <td>{formatPercentChange(controlAvgPpYy)}</td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
};

export default CampaignKeywordRankingChart;
