import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { MdOutlineChevronRight, MdOutlineChevronLeft } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '@redux/appSlice';
import T from '@json/en.json';

const MainSidebar = () => {
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector(state => state.app.settings);
  const [longTitle, setLongTitle] = useState(isSidebarOpen);

  useEffect(() => {
    setTimeout(() => setLongTitle(isSidebarOpen), isSidebarOpen ? 750 : 0);
  }, [isSidebarOpen]);

  const rowStyle = { opacity: Number(isSidebarOpen), transition: 'all 0.5s ease-out' };

  return (
    <aside className="main-sidebar px-0" style={{ width: isSidebarOpen ? 200 : 70 }}>
      <div className="main-navbar">
        <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <Link className="w-100" to="/dashboard">
            <h5 className="m-auto text-dark">{longTitle ? T.LEFTBAR.HEADER : 'IR'}</h5>
          </Link>
        </nav>
      </div>
      <div className="nav-wrapper" style={{ height: 'unset' }}>
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className={clsx(pathname === '/dashboard' && 'active', 'nav-link overflow-unset')} to="/dashboard">
              <i className="material-icons">view_module</i>
              <span style={rowStyle}>{T.LEFTBAR.DASHBOARD}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={clsx(pathname.startsWith('/campaigns') && 'active', 'nav-link overflow-unset')} to="/campaigns">
              <i className="material-icons">table_chart</i>
              <span style={rowStyle}>{T.LEFTBAR.ALLCAMPAIGN}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={clsx(['/linkcountdocuments', '/linkcountdocuments/add'].includes(pathname) && 'active', 'nav-link overflow-unset')} to="/linkcountdocuments">
              <i className="material-icons">insert_drive_file</i>
              <span style={rowStyle}>{T.LEFTBAR.LINKCOUNTDOC}</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={clsx(pathname === '/reports' && 'active', 'nav-link overflow-unset')} to="/reports">
              <i className="material-icons">assessment</i>
              <span style={rowStyle}>{T.LEFTBAR.ALLREPORT}</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="fs-3 mt-1 pe-1 text-right cursor-pointer" onClick={() => dispatch(toggleSidebar())}>
        {isSidebarOpen ? <MdOutlineChevronLeft /> : <MdOutlineChevronRight />}
      </div>
    </aside>
  );
};

export default MainSidebar;
