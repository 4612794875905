import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@components/ButtonLoader';
import T from '@json/en.json';
import { Container, Label, Input } from 'reactstrap';
import { isValidEmail, signIn, getToken } from '@utils';
import { setUser } from '@redux/appSlice';
import { showError } from '@components/Notifications.js';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from './loginApi';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [isEmailNotValid, setIsEmailNotValid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [isPasswordNotValid, setIsPasswordNotValid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [loginMutation, { isLoading }] = useLoginMutation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get('reload') === 'true') {
      history.replace({ pathname: '/login' });
      window.location.reload();
    }

    if (getToken()) {
      history.push('/dashboard');
    }
  }, [history, location]);

  const handleEmailChange = ({ target }) => {
    setFormData(i => ({ ...i, email: target.value }));

    if (!target.value) {
      setIsEmailNotValid(true);
      setEmailErrorMessage(T.ERRORS.EMPTY);
      return false;
    }

    if (!isValidEmail(target.value)) {
      setIsEmailNotValid(true);
      setEmailErrorMessage(T.ERRORS.EMAIL_NOT_VALID);
      return false;
    }

    setIsEmailNotValid(false);
  };

  const handlePasswordChange = ({ target }) => {
    setFormData(i => ({ ...i, password: target.value }));

    if (!target.value) {
      setIsPasswordNotValid(true);
      setPasswordErrorMessage(T.ERRORS.EMPTY);
      return false;
    }

    if (target.value.length < 6 || target.value.length > 15) {
      setIsPasswordNotValid(true);
      setPasswordErrorMessage(T.ERRORS.PASSWORD_LENTH);
      return false;
    }

    setIsPasswordNotValid(false);
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    const { data, error } = await loginMutation(formData);

    if (error) {
      return showError(error.data && error.data.detail ? error.data.detail : 'Login Error.');
    }

    const user = { ...data.user, auth: data.auth };

    signIn(user);

    dispatch(setUser(user));

    history.push('/dashboard');
  };

  return (
    <Container fluid className="p-2 p-sm-4 d-flex justify-content-center">
      <div className="col-12 col-sm-8 col-md-6 col-lg-4 pt-4">
        <div className="card mb-4">
          <div className="py-3 border-bottom text-center d-flex justify-content-center align-items-center">
            <img src="logo192.png" width="60" />
            <p className="mb-0 mx-3 fs-4 fw-bold">{T.LOGIN.MAIN_HEADER}</p>
          </div>
        </div>
        <div className="card card-small mb-4">
          <ul className="list-group list-group-flush">
            <li className="list-group-item p-3">
              <div className="row">
                <div className="col">
                  <form id="login-form" onSubmit={onFormSubmit}>
                    <div className="form-row">
                      <div className="form-group">
                        <Label>{T.LOGIN.EMAIL}</Label>
                        <Input value={formData.email} name="email" className="form-control" onChange={handleEmailChange} required={true} autoComplete="off" />
                        {isEmailNotValid && <span className="form-error">{emailErrorMessage}</span>}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <Label>{T.LOGIN.PASSWORD}</Label>
                        <Input
                          value={formData.password}
                          name="password"
                          className="form-control"
                          onChange={handlePasswordChange}
                          required={true}
                          type="password"
                          autoComplete="off"
                        />
                        {isPasswordNotValid && <span className="form-error">{passwordErrorMessage}</span>}
                      </div>
                    </div>
                    <Button loading={isLoading} className="btn btn-accent" type="submit" disabled={isEmailNotValid || isPasswordNotValid || !formData.email || !formData.password}>
                      {T.LOGIN.BUTTON}
                    </Button>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Login;
