import { useMemo } from 'react';
import { createYAxis, colors, getClientControlYRange } from '@shared/graph';
import { constants } from '@utils';
import BasicBarChar from '@components/BasicBarChar';

const RankDistChart = ({ monthwiseDistribution, segmentKey, dataConfig, campaignId, showLeftAxis = false, showRightAxis = true }) => {
  const { xAxis, data } = useMemo(() => {
    const data = {};
    const distribution = monthwiseDistribution ?? {};
    const xAxis = Object.keys(distribution);

    for (const value of Object.values(distribution)) {
      for (const segment of constants.KEYWORD_DISTRIBUTION_SEGEMENTS) {
        if (!data[segment.slug]) {
          data[segment.slug] = [];
        }
        data[segment.slug].push(value[segment.slug]);
      }

      if (!data['average']) {
        data['average'] = [];
      }

      data['average'].push(parseFloat(value['average'].toFixed(2)));
    }

    return { xAxis, data };
  }, [monthwiseDistribution]);

  const segmentZoom = useMemo(() => {
    if (!(segmentKey && dataConfig?.zoom?.keyword_distribution)) {
      return {};
    }

    return dataConfig.zoom.keyword_distribution[segmentKey] || {};
  }, [dataConfig, segmentKey]);

  const max = useMemo(() => Math.max(...Object.values(monthwiseDistribution ?? {}).map(dist => dist.count)), [monthwiseDistribution]);

  const chr = {
    options: {
      chart: {
        id: `keyword-distribution|${segmentKey || '?'}`,
      },
      xaxis: {
        categories: xAxis,
      },
      yaxis: [
        createYAxis({
          showLabel: true,
          color: colors.black,
          title: 'Count of Ranking KWs',
          reversed: true,
          min: 0,
          max,
          showIntegersOnY: true,
          labels: {
            formatter: ({ value }) => value,
          },
        }),
        createYAxis({
          seriesName: 'Average Rank',
          opposite: true,
          reversed: true,
          showLabel: true,
          color: colors.black,
          title: 'Average Rank',
          labels: {
            formatter: ({ value }) => value,
          },
          ...getClientControlYRange({ clientArr: [0, max], controlArr: data.average || [], zoom: segmentZoom.right }).control,
        }),
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ['#fff'],
        width: [1, 1, 1, 1, 4],
        dashArray: 0,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        inverseOrder: false,
        followCursor: false,
        enabledOnSeries: [0, 1, 2, 3, 4],
      },
    },
    series: [
      ...constants.KEYWORD_DISTRIBUTION_SEGEMENTS.map(i => ({
        name: i.text,
        type: 'column',
        data: data[i.slug] || [],
        color: i.color,
        stacking: 'normal',
        stack: 1,
        yAxis: 0,
      })),
      {
        name: 'Average Rank',
        type: 'spline',
        data: data.average || [],
        color: colors.teal,
        yAxis: 1,
      },
    ],
  };

  return (
    <BasicBarChar
      sectionKey="keyword_distribution"
      segmentKey={segmentKey}
      dataConfig={dataConfig}
      campaignId={campaignId}
      showLeftAxis={showLeftAxis}
      showRightAxis={showRightAxis}
      rightRange={{ min: chr.options.yaxis[0].min, max: chr.options.yaxis[0].max }}
      graphStyle={{ maxWidth: 720 }}
      chr={chr}
    />
  );
};

export default RankDistChart;
