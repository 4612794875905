import { memo, useEffect } from 'react';
import { Table, Col, Card, CardBody } from 'reactstrap';
import { useGetSOVQuery } from '@components/campaigns/campaignsApi';
import { Spinner } from 'reactstrap';
import { formatPercentChange } from '@utils';

const CampaignSOVTable = ({ campaignId, onLoadEnd }) => {
  const { data, isFetching, status } = useGetSOVQuery({ id: campaignId });

  useEffect(() => {
    if (status === 'fulfilled') {
      onLoadEnd && onLoadEnd();
    }
  }, [onLoadEnd, status]);

  if (isFetching) {
    return (
      <div className="d-flex align-items-center my-6">
        <Spinner size="sm" className="mx-1" />
        <p className="mx-1 my-0">Loading table...</p>
      </div>
    );
  }

  if (!data || !data.metrics) {
    return <p className="text-center mt-3">No graph data for the current period</p>;
  }

  const { month_keys, metrics } = data;
  const keyLength = month_keys.length;
  const currentMonth = month_keys[keyLength - 1];
  const previousMonth = month_keys[keyLength - 2];
  const firstMonth = month_keys[0];

  return (
    <Col>
      <Card className="mb-4">
        <CardBody className="p-0 pb-3">
          <Table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th></th>
                {firstMonth && firstMonth != previousMonth && <th scope="col">{firstMonth}</th>}
                {previousMonth && <th scope="col">{previousMonth}</th>}
                <th scope="col">{currentMonth}</th>
                <th scope="col">M/M</th>
                <th scope="col">% Diff</th>
                <th scope="col">{metrics.client?.yy_average ? 'Y/Y' : 'P/P'}</th>
                <th scope="col">% Diff</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Campaign</strong>
                </td>
                {firstMonth && firstMonth != previousMonth && <td scope="col">{data.sov_avg_monthwise?.client[firstMonth]}%</td>}
                {previousMonth && <td scope="col">{data.sov_avg_monthwise?.client[previousMonth]}%</td>}
                <td>{data.sov_avg_monthwise?.client[currentMonth] || 0}%</td>
                <td>{formatPercentChange(metrics.client?.mm_average)}</td>
                <td>{metrics.mm_diff}%</td>
                <td>{formatPercentChange(metrics.client?.yy_average ? metrics.client.yy_average : metrics.client?.pp_average)}</td>
                <td>{`${metrics.client?.yy_average ? metrics.yy_diff : metrics.pp_diff}%`}</td>
              </tr>
              <tr>
                <td className="fix-rowspan-text">
                  <strong>Control</strong>
                </td>
                {firstMonth && firstMonth != previousMonth && <td scope="col">{data.sov_avg_monthwise?.control[firstMonth]}%</td>}
                {previousMonth && <td scope="col">{data.sov_avg_monthwise?.control[previousMonth]}%</td>}
                <td>{data.sov_avg_monthwise?.control[currentMonth]}%</td>
                <td>{formatPercentChange(metrics.control?.mm_average)}</td>
                <td></td>
                <td>{formatPercentChange(metrics.control?.yy_average ? metrics.control.yy_average : metrics.control?.pp_average)}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default memo(CampaignSOVTable);
