import { toggleColumnVisibility } from '@redux/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import T from '@json/en.json';
import { useState } from 'react';

const DropdownHideableColumns = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const columnSelector = useSelector(state => state.app.settings.allCampaigns.columns);
  const dispatch = useDispatch();

  const handleDropdownToggle = () => {
    setIsDropdownOpen(old => !old);
  };

  const handleColumnVisibility = e => {
    dispatch(toggleColumnVisibility({ column: e.target.name }));
  };

  const renderColumn = col => {
    return (
      <DropdownItem toggle text key={col} active={columnSelector[col].visible} style={{ paddingLeft: 4, paddingRight: 4 }}>
        <Input type="checkbox" className="custom-control-input ms-1" name={col} onChange={handleColumnVisibility} checked={columnSelector[col].visible} />
        <Label className="ms-2 mb-1" style={{ fontWeight: 400, fontSize: 15 }}>
          {T.CAMPAIGN.FIELD_NAMES[col.toUpperCase()] || col}
        </Label>
      </DropdownItem>
    );
  };

  return (
    <Dropdown toggle={handleDropdownToggle} isOpen={isDropdownOpen} className="mx-3">
      <DropdownToggle caret className="btn btn-outline-primary">
        Columns
      </DropdownToggle>
      <DropdownMenu container={'body'} style={{ paddingTop: 0, paddingBottom: 0 }} end key={Math.random()}>
        {Object.keys(columnSelector)
          .sort((a, b) => columnSelector[a].order - columnSelector[b].order)
          .map(renderColumn)}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownHideableColumns;
