import { Row, Col, Label } from 'reactstrap';
import T from '@json/en.json';
import Button from '@components/ButtonLoader';
import DatePicker from 'react-datepicker';
import { getLastMonth } from '@utils';
import clsx from 'clsx';

const StartEndMonthYearPicker = ({
  startDateHandler,
  endDateHandler,
  startDate,
  endDate,
  onButtonClick,
  formDataError = {},
  loading = false,
  submitText,
  labelStartText = 'Start',
  labelEndText = 'End',
  buttonDisabled = false,
  buttonTitle = '',
  ...props
}) => {
  const handleClick = () => {
    if (buttonDisabled) {
      return false;
    }
    onButtonClick && onButtonClick(startDate, endDate);
  };

  return (
    <div {...props}>
      <Row>
        <Col>
          <div className="start-date-picker">
            <Label>
              {`${labelStartText}`}
              {labelStartText.includes('Report') ? '*' : ''}
            </Label>
            <DatePicker
              showMonthYearPicker
              selected={startDate}
              name="start_date"
              className="form-control"
              dateFormat="MMMM yyyy"
              placeholderText={T.CAMPAIGN.SELECT_DATE}
              onChange={startDateHandler}
              maxDate={getLastMonth()}
              autoComplete="off"
              disabled={loading}
            />
            {formDataError.start && <span className="form-error">{T.ERRORS.EMPTY}</span>}
          </div>
        </Col>
        <Col>
          <div className="end-date-picker">
            <Label>{`${labelEndText}`}*</Label>
            <DatePicker
              showMonthYearPicker
              selected={endDate}
              name="end_date"
              className="form-control"
              dateFormat="MMMM yyyy"
              placeholderText={T.CAMPAIGN.SELECT_DATE}
              onChange={endDateHandler}
              maxDate={getLastMonth()}
              minDate={startDate}
              autoComplete="off"
              disabled={loading}
            />
            {formDataError.end && <span className="form-error">{T.ERRORS.EMPTY}</span>}
          </div>
        </Col>
        <Col className="col-auto d-flex align-items-end">
          <Button className={clsx('btn btn-accent', buttonDisabled && 'opacity-40')} title={buttonTitle} type="button" onClick={handleClick} loading={loading}>
            {submitText}
          </Button>
        </Col>
      </Row>
      {endDate && startDate > endDate && <span className="form-error">{T.ERRORS.DATE_RANGE_ERROR}</span>}
    </div>
  );
};

export default StartEndMonthYearPicker;
