import { memo } from 'react';
import { Table, Col, Card, CardBody } from 'reactstrap';
import { useGetAuthorityQuery } from '@components/campaigns/campaignsApi';
import { Spinner } from 'reactstrap';
import clsx from 'clsx';
import { formatPercentChange } from '@utils';

const CampaignPageAuthTable = ({ campaignId }) => {
  const { data, isFetching } = useGetAuthorityQuery(campaignId);

  if (isFetching) {
    return (
      <div className="text-center mt-3">
        <Spinner />
      </div>
    );
  }

  if (!data || !data.client_url_pa || !data.metrics) {
    return <p className="text-center mt-3">No graph data for the current period</p>;
  }

  const { client_url_pa, metrics, month_keys } = data;
  const keyLength = month_keys.length;
  const currentMonth = data.month_keys[keyLength - 1];
  const previousMonth = data.month_keys[keyLength - 2];
  const firstMonth = data.month_keys[0];
  const showYColumn = Object.keys(client_url_pa).filter(url_pa => client_url_pa[url_pa].yy).length > 0;

  return (
    <Col>
      <Card className="mb-4">
        <CardBody className="p-0 pb-3">
          <Table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th>URL</th>
                {firstMonth && firstMonth != previousMonth && <th scope="col">{firstMonth}</th>}
                {previousMonth && <th scope="col">{previousMonth}</th>}
                <th scope="col">{currentMonth}</th>
                <th scope="col">M/M</th>
                <th scope="col">{showYColumn ? 'Y/Y' : 'P/P'}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(client_url_pa).map(url => {
                return (
                  <tr key={url}>
                    <td>{url}</td>
                    {firstMonth && firstMonth != previousMonth && <td scope="col">{client_url_pa[url].third_last_pa}</td>}
                    {previousMonth && <td scope="col">{client_url_pa[url].previous_pa}</td>}
                    <td>{client_url_pa[url].current_pa}</td>
                    <td>{formatPercentChange(client_url_pa[url].mm)}</td>
                    <td>{formatPercentChange(showYColumn ? client_url_pa[url].yy : client_url_pa[url].pp)}</td>
                  </tr>
                );
              })}
            </tbody>
            <Footer metrics={metrics} showYColumn={showYColumn} totalMonths={keyLength} />
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};

const Footer = memo(({ metrics: { yy_diff, mm_diff, pp_diff, client, control }, showYColumn, totalMonths }) => {
  if (!(client && control)) {
    return null;
  }

  const colSpan = totalMonths > 2 ? 4 : totalMonths > 1 ? 3 : 2;
  const Row = ({ cols = [], strong = false }) => {
    return (
      <tr className={clsx(strong && 'fw-bold')}>
        <td className="text-right" colSpan={colSpan}>
          {cols[0]}
        </td>
        <td>{`${Number(cols[1]).toFixed(2)}%`}</td>
        <td>{`${showYColumn ? Number(cols[2]).toFixed(2) : Number(cols[3]).toFixed(2)}%`}</td>
      </tr>
    );
  };

  return (
    <tfoot className="border-0">
      <Row cols={['Average for all campaign pages', client.mm_average, client.yy_average, client.pp_average]} />
      <Row cols={['Average for all control pages', control.mm_average, control.yy_average, control.pp_average]} />
      <Row cols={['Difference', mm_diff, yy_diff, pp_diff]} strong />
    </tfoot>
  );
});

Footer.displayName = 'Footer';

export default CampaignPageAuthTable;
