import clsx from 'clsx';
import T from '@json/en.json';

const CampaignCheckbox = ({ isUpdating, setSelectedCampaigns, campaign, updateMutation }) => {
  return (
    <div className="text-center">
      <input
        className={clsx('cursor-pointer', isUpdating && 'opacity-10')}
        title={T.CAMPAIGN.FIELD_NAMES.INACTIVE}
        type="checkbox"
        checked={!campaign.is_active}
        onChange={({ target }) => {
          if (target.checked) {
            setSelectedCampaigns(items => items.filter(item => campaign.id !== item.id));
          }
          updateMutation({ ...campaign, is_active: !target.checked });
        }}
        disabled={Boolean(isUpdating || campaign.status !== 2)}
      />
    </div>
  );
};
export default CampaignCheckbox;
