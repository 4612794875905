import { useMemo } from 'react';
import { useGetAuthorityQuery } from '@components/campaigns/campaignsApi';
import clsx from 'clsx';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import QEditor from '@elements/QEditor/QEditor';
import { getClientControlYRange, createYAxis, colors } from '@shared/graph';

const PageAuthGraph = ({ updateContent, graph_data, isSaving, campaignId }) => {
  const { data: pageAuthData, isFetching } = useGetAuthorityQuery(campaignId);

  const { clientsList, controlList } = useMemo(() => {
    const initData = { clientsList: [], controlList: [] };

    if (!pageAuthData) {
      return initData;
    }

    return pageAuthData.month_keys.reduce((acc, month) => {
      acc.clientsList.push(pageAuthData.pa_avg_monthwise['client']?.[month] || 0);
      acc.controlList.push(pageAuthData.pa_avg_monthwise['control']?.[month] || 0);

      return acc;
    }, initData);
  }, [pageAuthData]);

  const chr = {
    options: {
      chart: {
        id: 'page-authority',
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: pageAuthData ? pageAuthData.month_keys : [],
      },
      yaxis: [
        createYAxis({
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          color: colors.black,
          title: 'Campaign',
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList }).client,
        }),
        createYAxis({
          opposite: true,
          showAxisBorder: true,
          showAxisTicks: true,
          showLabel: true,
          color: colors.black,
          title: 'Control',
          ...getClientControlYRange({ clientArr: clientsList, controlArr: controlList }).control,
        }),
      ],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: undefined,
        width: [0, 2],
        dashArray: 0,
      },
    },
    series: [
      {
        name: 'Average Page Authority',
        data: clientsList,
        type: 'column',
        yAxis: 0,
      },
      {
        name: 'Control Page Authority',
        data: controlList,
        type: 'spline',
        color: '#00e396',
        yAxis: 1,
      },
    ],
    fill: {
      type: 'solid',
      colors: ['#B32824'],
    },
  };

  const highchartsOptions = {
    title: { text: null },
    xAxis: [{ categories: chr.options.xaxis.categories, crosshair: true }],
    yAxis: chr.options.yaxis,
    series: chr.series,
    plotOptions: chr.options.plotOptions || {},
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div className="w-100 mw-100">
          <QEditor isSaving={isSaving} initialContent={graph_data?.page_authority?.description || ''} updateContent={content => updateContent(content, 'page_authority')} />
        </div>
      </div>
      {clientsList.length > 0 && (
        <div className="d-flex flex-wrap justify-content-center">
          <h5 className="text-center fw-bolder w-100">Average Page Authority</h5>
          <div className="w-100">
            <HighchartsReact
              containerProps={{ style: { maxHeight: 400, maxWidth: 720 }, className: clsx('mx-auto', isFetching && 'dim') }}
              highcharts={Highcharts}
              options={highchartsOptions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAuthGraph;
